import React, { useState, useCallback, useEffect, useRef, CSSProperties } from "react";
import { Node, InputNode, InferenceNode, DisplayNode, nodeStringTypeMap, SpreadsheetInputNode,
  ScrapeInputNode,
  LinkedInProfileInputNode,
  SearchInputNode,
  InferenceAINode,
  SpreadsheetOutputNode,
  HTMLOutputNode,
  CustomNode
} from './Node';
import { useCSVReader } from 'react-papaparse';
import { useAuth0 } from "@auth0/auth0-react";


// import Tags, { MixedTags } from "@yaireo/tagify/dist/react.tagify";
import Select from 'react-dropdown-select';
import { Importer, ImporterField } from 'react-csv-importer';
import ReactJson from 'react-json-view';

import 'react-csv-importer/dist/index.css';

const csv_styles = {
  zone: {
    alignItems: 'center',
    borderWidth: 2,
    borderStyle: 'dashed',
    borderColor: 'black',
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    padding: 20,
  },
  // ... (rest of the styles)
};

const CSVFileInput2 = ({ defaultValue, onInputChange, updateOutputProps }) => {
  const [csvData, setCsvData] = useState(defaultValue);
  const { CSVReader } = useCSVReader();

  const handleOnUploadAccepted = (results) => {
    setCsvData(results.data);
    onInputChange(results.data);

    if (results.data.length > 0) {
      const firstRowKeys = Object.keys(results.data[0]);
      const mappedToType = [
        firstRowKeys.reduce((acc, key) => {
          acc[key] = typeof results.data[0][key];
          return acc;
        }, {}),
      ];
      updateOutputProps(mappedToType);
    }
  };

  return (
    <div>
      {csvData ? (
        <ReactJson
          src={csvData}
          theme="summerfruit:inverted"
          onEdit={false}
          onAdd={false}
          onDelete={false}
          displayObjectSize={false}
          displayDataTypes={false}
          iconStyle="square"
          indentWidth={2}
          name={false}
        />
      ) : (
        <CSVReader
          onUploadAccepted={handleOnUploadAccepted}
          config={{
            header: true,
            dynamicTyping: true,
          }}
        >
          {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps, Remove }) => (
            <div {...getRootProps()} style={csv_styles.zone}>
              {acceptedFile ? (
                <>
                  <div style={csv_styles.file}>
                    {/* ... */}
                  </div>
                </>
              ) : (
                'Click to upload'
              )}
            </div>
          )}
        </CSVReader>
      )}
    </div>
  );
};


// Tagify settings object
const settings = {
  pattern: /@/,  // <- must define "patten" in mixed mode
  dropdown: {
    enabled: 1,
    position: "text"
  },
  originalInputValueFormat: valuesArr => valuesArr.id,
  enforceWhitelist: true,
  whitelist: [
    // {id: 109, value: "Mr. Mackey", title: "M'Kay"}
  ]
}

const matryxSelectOptions = [  
  {
  value: 1,
  label: 'Leanne Graham'
  },
  {
    value: 2,
    label: 'Ervin Howell'
  }
]


const DropdownSelect = ({ defaultValue, enums, onInputChange }) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue || '');

  const handleChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    onInputChange(newValue);
  };

  return (
    <select className='attr-value' value={selectedValue} onChange={handleChange}>
      {enums.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};


const MatryxSelect = ({value, whitelist, onInputChange}) => {
  // const selectRef = useRef(null);
  const dropdownRef = useRef(null);

  const [inputValue, setInputValue] = useState(value);
  const [keepOpen, setKeepOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectOptions, setSelectOptions] = useState(matryxSelectOptions)
  const [currentTextInputCursorIdx, setCurrentTextInputCursorIdx] = useState(0)
  const [focusedOption, setFocusedOption] = useState(null);


  // const openDropdown = () => {
  //   if (selectRef.current) {
  //     selectRef.current.open();
  //   }
  // };

  const handleKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      const currentIndex = selectOptions.findIndex(
        (option) => option.value === focusedOption?.value
      );
      const nextIndex = (currentIndex + 1) % selectOptions.length;
      setFocusedOption(selectOptions[nextIndex]);
    } else if (event.key === 'Enter') {
      if (focusedOption) {

        handleOptionSelect(focusedOption);
      }
    }
  };


  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    onInputChange(event.target.value);
  };

  const handleOptionSelect = (selectedOption) => {
    const input_string = inputValue
    const matches = input_string.match(/\{\{([\w.\[\]0-9]+)\}\}/g);

    if (matches.length == 0) {
      return
    } 
    const capturedGroups = matches ? matches.map(([, group]) => group) : [];
    
    const cursorPosition = currentTextInputCursorIdx


    const matchAtCursor = matches?.find((match) => {
      const startIndex = input_string.indexOf(match);
      const endIndex = startIndex + match.length;
      return cursorPosition >= startIndex && cursorPosition < endIndex;
    });

    const matchStartIdx = matchAtCursor ? input_string.indexOf(matchAtCursor)  : -1;    
    const matchEndIdx = matchAtCursor ? input_string.indexOf(matchAtCursor) + matchAtCursor.length : -1;    
    
    

    const replacedInputText = input_string.slice(0, matchStartIdx) + `{{${ selectedOption.label }}}` + input_string.slice(matchEndIdx, -1)
    const validPaths = whitelist
    const validSelectOptions = validPaths.map((path, index) => ({
      value: index, // or value: path, if you want the path to be the value
      label: path
    }));

    setSelectOptions(validSelectOptions)
    setInputValue(replacedInputText)
    onInputChange(replacedInputText);
    setIsOpen(false);
    setFocusedOption(null);
  };


  const searchForMatches = () => {

  }

  const getMatryxSelectWhitelist = () => {
    return []
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);


  const onChange = (event) => {
    setFocusedOption(null);

    const input_string = event.target.value
    const matches = input_string.match(/\{\{([\w.\[\]0-9]+)\}\}/g);

    // const matches = input_string.match(/\{\{([\w.\[\]0-9]+)\}\}/g);
    const capturedGroups = matches ? matches.map(([, group]) => group) : [];
    
    const cursorPosition = event.target.selectionStart !== undefined ? event.target.selectionStart : 0;
    setCurrentTextInputCursorIdx(cursorPosition)


    const matchAtCursor = matches?.find((match) => {
      const startIndex = input_string.indexOf(match);
      const endIndex = startIndex + match.length;
      return cursorPosition >= startIndex && cursorPosition < endIndex;
    });


    if (!matchAtCursor) {
      setIsOpen(false)
      setInputValue(input_string)
      onInputChange(input_string);
      

      return
    } 
    setIsOpen(true)


    const matchStartIdx = matchAtCursor ? input_string.indexOf(matchAtCursor) : -1;    
    
    
    // const whitelist = whitelist // fetchNewWhitelist from current state of graph
    // TODO make valid paths from whitelist
    // const validPaths = getValidPaths()
    const validPaths = whitelist

    const validSelectOptions = validPaths.map((path, index) => ({
      value: index, // or value: path, if you want the path to be the value
      label: path
    }));

    setSelectOptions(validSelectOptions)
    
    setInputValue(input_string)
    onInputChange(input_string);
    setFocusedOption(null);

    // console.log("-+++--", whitelist)
    // console.log()


    


    
    // Make a list of options

    // replace = find matchAtCusor in matchStartIdx, replace with {{option selected}}


    // Check regex for matches
    // Find the match where the cursor is
    // setOptions a list of dummy paths
    // make dummy paths whitelist
    // select replaces the text within that match
    // red/green with list of dummy paths



  }

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  return (
    <div ref={dropdownRef} className="custom-dropdown" onKeyDown={handleKeyDown}>
      <textarea 
        className="attr-value"
        placeholder={inputValue}
        value={inputValue}
        onChange={onChange}
      />
      {isOpen && (
        <ul className="dropdown-options">
          {selectOptions.map((option) => (
            <li key={option.value} onClick={() => handleOptionSelect(option)}>
              {option.label}
            </li>
          ))}
        </ul>
      )}
        {/* <Select
          // className="matryx-select"
          // DropdownIndicator={() => {undefined}}
          keepOpen={keepOpen}
          options={selectOptions}
          closeOnSelect={true}
          onChange={handleOptionSelect} // In one of these, I need to replace the selected match with the newly selected list item value
          onInputChange={handleInputChange}
          inputValue={inputValue}
        /> */}
    </div>
  )
}


const CSVFileInput = ({ defaultValue, onInputChange, updateOutputProps }) => {
  // Note: returns csvData as [{"Date": "3/13/2024 22:33:00","Status": "success"}] 
  const [csvData, setCsvData] = useState(defaultValue);
  // const [uploadState, setUploadState] = useState('')
  // csvData = [{"Date": "3/13/2024 22:33:00","Status": "success"}]
  const [columns, setColumns] = useState([]);

  const onStartHandler = async ({ file, preview, fields, columnFields }) => {
    setColumns(columns);
  }

  const handleDataHandler = async (rows) => {
    if (rows.length == 1) {
      return
    }

    setCsvData(rows);
    
    onInputChange(rows);

    const newOutputDict = {
      csv_rows: rows,
    };
    
    // Update the output props with the new output dictionary
    // Object.keys(newOutputDict.csv_rows[0])

    if (newOutputDict.csv_rows.length > 0) {
      const firstRowKeys = Object.keys(newOutputDict.csv_rows[0]);
      // { x: 'string', y: 'string' }
      const mappedToType = [firstRowKeys.reduce((acc, key) => {
        acc[key] = 'string'; // Map each key to 'string'
        return acc;
      }, {})];
      
      updateOutputProps(mappedToType);
      console.log(mappedToType);
    }

    // setUploadState('complete')
  };

  return (
    <div>
      {csvData ?  
        <ReactJson
          src={csvData}
          theme="summerfruit:inverted"
          onEdit={false}
          onAdd={false}
          onDelete={false}
          displayObjectSize={false}
          displayDataTypes={false}
          iconStyle="square"
          indentWidth={2}
          name={false}
        />

        :

        <Importer
          dataHandler={handleDataHandler}
          restartable={false}
          onStart={onStartHandler}
          onComplete={() => {
            console.log('CSV import complete');
          }}
        >
          {columns.map((column) => (
            <ImporterField key={column?.header || ""} name={column?.header || ""} label={column?.header || ""} />
          ))}
        </Importer>

      }
    </div>
  );
};

const JSONOutput = ({ jsonData, editable, onInputChange }) => {
  return (
    <ReactJson
      src={jsonData}
      theme="summerfruit:inverted"
      onEdit={editable ? ({updated_src, name, namespace, new_value, existing_value}) => { onInputChange(updated_src) } : false}
      onAdd={editable ? ({updated_src, name, namespace, new_value, existing_value}) => { onInputChange(updated_src) } : false}
      onDelete={false}
      displayObjectSize={false}
      displayDataTypes={false}
      iconStyle="square"
      indentWidth={2}
      name={false}
    />
  );
};



const MixedModeTagify = ({value, whitelist, onInputChange}) => {
  settings.whitelist = whitelist

  const onChange2 = e => {
    // When a tag has been modified

    onInputChange(e.detail.tagify.DOM.originalInput.value)
  }

  const onChange3 = (e) => {
    // When any non tag text has been modified
    // TODO: Is there something that changes both??? There is a bug where you need to add spaces to the end of the text

    onInputChange(e.detail.tagify.DOM.originalInput.value)

  }

  return (
    <>
      {/* <Tags 
        InputMode="textarea"
        autoFocus={true}
        settings={settings}
        className="attr-value"
        readOnly={false}
        onInput={onChange3}
        onChange={onChange2}
        defaultValue={value}
      /> */}
    </>
  )
}

const NodesSidebar = ({ open }) => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  // State to manage the open state of the Sidebar

  return (
    <aside style={{display: open ? '' : 'none'}}>
      <h2 className="description2">
        Drag Nodes
      </h2>

      <div className="nodes-wrapper-nodes-sidebar">
 
      <CustomNode 
        data={{
          'label': 'Google Search', 
          'functionName': 'search_google',
          'icon': 'google-logo', 
          "type": "input", 
          'color': 'green',
          'inputProps': [
            {
              'id': 'description',
              'label': 'description', 
              'type': 'string',
              'component_type': 'text_input',
              'value': 'Advanced Search',
            },
            {
              'id': 'query',
              'label': 'query',
              'type': 'string', 
              'component_type': 'large_text_input',
              'value': '',
            },
            {
              'id': 'max_results',
              'label': 'max_results',
              'type': 'string',
              'component_type': 'text_input', 
              'value': '5',
            },
            {
              'id': 'time_period',
              'label': 'time_period',
              'type': 'string',
              'component_type': 'text_input',
              'value': '',
            },
            {
              'id': 'location',
              'label': 'location',
              'type': 'string',
              'component_type': 'text_input',
              'value': '',
            }
          ],
          'outputProps': { 
            search_results: [ { 
              link: 'string', 
              snippet: 'string', 
              source: 'string',
              title: 'string',
              date: 'string',
            } ] 
          }
        }}
      />

      <CustomNode 
        data={{
          'label': 'Similarweb Analytics', 
          'functionName': 'apify_similarweb',
          'icon': 'similarweb-logo', 
          "type": "input", 
          'color': 'green',
          'inputProps': [
            {
              'id': 'description',
              'label': 'description', 
              'type': 'string',
              'component_type': 'text_input',
              'value': 'Advanced Search',
            },
            {
              'id': 'domain',
              'label': 'Domain',
              'type': 'string', 
              'component_type': 'large_text_input',
              'value': '',
            },
            {
              'id': 'url',
              'label': 'URL',
              'type': 'string',
              'component_type': 'large_text_input', 
              'value': '',
            },
          ],
          'outputProps': { 
            'visits': 'string',
            'pagesPerVisit': 'string',
            'category': 'string',
            'categoryRank': 'string',
            'estimatedMonthlyVisits': 'string',
            'trafficSources': 'string',
          }
        }}
      />

    {/* <CustomNode 
      data={{
        'label': 'Spreadsheet Input*',  // Skip for now
        'icon': 'google-sheets', 
        "type": "input", 
        'color': 'green',
        'inputProps': [
          {
            'id': 'description',
            'label': 'description',
            'type': 'string',
            'component_type': 'text_input',
            'value': 'Sheets Input',
          },
          {
            'id': 'csv_file',
            'label': 'csv_file',
            'type': 'string',
            'component_type': 'text_input',
            'value': '',
          },
          {
            'id': 'worksheet_name',
            'label': 'worksheet_name',
            'type': 'string',
            'component_type': 'text_input',
            'value': '',
          },
          {
            'id': 'mode',
            'label': 'mode',
            'type': 'string',
            'component_type': 'text_input',
            'value': 'append',
          },
          {
            'id': 'headers',
            'label': 'headers',
            'type': 'string',
            'component_type': 'text_input',
            'value': '',
          }
        ],
        'outputProps': {
          rows: [],
        },
      }}
    /> */}

        <CustomNode 
          data={{
            'label': 'CSV Upload',
            'functionName': 'csv_upload_input',
            'icon': 'file-upload', 
            "type": "input", 
            'color': 'green',
            'inputProps': [
              {
                'id': 'description',
                'label': 'Description',
                'type': 'string',
                'component_type': 'text_input',
                'value': 'Upload File',
              },
              {
                'id': 'csv_data',
                'label': 'Upload CSV',
                'type': 'list',
                'component_type': 'csv_file_input',
                'value': '',
              },
            ],
            'outputProps': {
              response: {}
            },
          }}
        />

        <CustomNode 
          data={{
            'label': 'Apollo People Search', 
            'icon': 'apollo-logo', 
            'functionName': 'search_people_via_apollo', 
            "type": "input", 
            'color': 'green',
            'inputProps': [
              {
                'id': 'description',
                'label': 'description',
                'functionName': 'search_people_via_apollo',
                'type': 'string',
                'component_type': 'text_input',
                'value': 'Search People Characteristics',
              },
              {
                'id': 'query',
                'label': 'query',
                'type': 'string',
                'component_type': 'text_input',
                'value': '',
              },
              {
                'id': 'person_titles',
                'label': 'person_titles',
                'type': 'string',
                'component_type': 'text_input',
                'value': '',
              },
              {
                'id': 'prospected_by_current_team',
                'label': 'prospected_by_current_team',
                'type': 'string',
                'component_type': 'text_input',
                'value': '',
              },
              {
                'id': 'person_locations',
                'label': 'person_locations',
                'type': 'string',
                'component_type': 'text_input',
                'value': '',
              },
              {
                'id': 'person_seniorities',
                'label': 'person_seniorities',
                'type': 'string',
                'component_type': 'text_input',
                'value': '',
              },
              {
                'id': 'contact_email_status',
                'label': 'contact_email_status',
                'type': 'string',
                'component_type': 'text_input',
                'value': '',
              },
              {
                'id': 'q_organization_domains',
                'label': 'q_organization_domains',
                'type': 'string',
                'component_type': 'text_input',
                'value': '',
              },
              {
                'id': 'organization_locations',
                'label': 'organization_locations',
                'type': 'string',
                'component_type': 'text_input',
                'value': '',
              },
              {
                'id': 'organization_ids',
                'label': 'organization_ids',
                'type': 'string',
                'component_type': 'text_input',
                'value': '',
              },
              {
                'id': 'organization_num_employees_ranges',
                'label': 'organization_num_employees_ranges',
                'type': 'string',
                'component_type': 'text_input',
                'value': '',
              },
              {
                'id': 'max_results',
                'label': 'max_results',
                'type': 'string',
                'component_type': 'text_input',
                'value': '20', // Default value updated to match the Python function's default
              }
            ],
            'outputProps': { 
              search_results: [ {
                'id': 'string',
                'first_name': 'string',
                'last_name': 'string',
                'name': 'string',
                'linkedin_url': 'string',
                'title': 'string',
                'email_status': 'string',
                'photo_url': 'string',
                'twitter_url': 'string',
                'github_url': 'string',
                'facebook_url': 'string',
                'extrapolated_email_confidence': 'string',
                'headline': 'string',
                'email': 'string',
                'organization_id': 'string',
                'employment_history': 'string',
                'state': 'string',
                'city': 'string',
                'country': 'string',
                'organization': 'string',
                'is_likely_to_engage': 'string',
                'departments': 'string',
                'subdepartments': 'string',
                'seniority': 'string',
                'functions': 'string',
                'phone_numbers': 'string',
                'intent_strength': 'string',
                'show_intent': 'string',
                'revealed_for_current_team': 'string'
              } ] 
            }
          }}
        />

      <CustomNode 
        data={{
          'label': 'Apollo Company Search', 
          'functionName': 'search_companies_via_apollo', 
          'icon': 'apollo-logo', 
          "type": "input", 
          'color': 'green',
          'inputProps': [
            {
              'id': 'description',
              'label': 'description',
              'type': 'string',
              'component_type': 'text_input',
              'value': 'Search Company Characteristics',
            },
            {
              'id': 'query',
              'label': 'query',
              'type': 'string',
              'component_type': 'text_input',
              'value': '',
            },
            {
              'id': 'max_results',
              'label': 'max_results',
              'type': 'string',
              'component_type': 'text_input',
              'value': '50',
            },
            {
              'id': 'organization_num_employees_ranges',
              'label': 'organization_num_employees_ranges',
              'type': 'string',
              'component_type': 'text_input',
              'value': '',
            },
            {
              'id': 'organization_locations',
              'label': 'organization_locations',
              'type': 'string',
              'component_type': 'text_input',
              'value': '',
            },
            {
              'id': 'q_organization_keyword_tags',
              'label': 'q_organization_keyword_tags',
              'type': 'string',
              'component_type': 'text_input',
              'value': '',
            },
            {
              'id': 'q_organization_name',
              'label': 'q_organization_name',
              'type': 'string',
              'component_type': 'text_input',
              'value': '',
            }
          ],
          'outputProps': {
            search_results: [{
              'id': 'string',
              'name': 'string',
              'website_url': 'string',
              'blog_url': 'string',
              'angellist_url': 'string',
              'linkedin_url': 'string',
              'twitter_url': 'string',
              'facebook_url': 'string',
              'primary_phone': 'string',
              'languages': 'string',
              'alexa_ranking': 'string',
              'phone': 'string',
              'linkedin_uid': 'string',
              'founded_year': 'string',
              'publicly_traded_symbol': 'string',
              'publicly_traded_exchange': 'string',
              'logo_url': 'string',
              'crunchbase_url': 'string',
              'primary_domain': 'string',
              'owned_by_organization_id': 'string',
              'intent_strength': 'string',
              'show_intent': 'string',
              'has_intent_signal_account': 'string'
            }]
          },
        }}
      />

      <CustomNode 
        data={{
          'label': 'Apollo Enrich Person', 
          'icon': 'apollo-logo', 
          'functionName': 'enrich_via_apollo', 
          "type": "input", 
          'color': 'blue',
          'inputProps': [
            {
              'id': 'description',
              'label': 'description',
              'functionName': 'enrich_via_apollo',
              'type': 'string',
              'component_type': 'text_input',
              'value': 'Enrich Person Data',
            },
            {
              'id': 'first_name',
              'label': 'first_name',
              'type': 'string',
              'component_type': 'text_input',
              'value': '',
            },
            {
              'id': 'last_name',
              'label': 'last_name',
              'type': 'string',
              'component_type': 'text_input',
              'value': '',
            },
            {
              'id': 'email',
              'label': 'email',
              'type': 'string',
              'component_type': 'text_input',
              'value': '',
            },
            {
              'id': 'organization_name',
              'label': 'organization_name',
              'type': 'string',
              'component_type': 'text_input',
              'value': '',
            },
            {
              'id': 'linkedin_url',
              'label': 'linkedin_url',
              'type': 'string',
              'component_type': 'text_input',
              'value': '',
            },
            {
              'id': 'domain',
              'label': 'domain',
              'type': 'string',
              'component_type': 'text_input',
              'value': '',
            }
          ],
          'outputProps': {
            // 'credits_consumed': 0,
            'error_code': "string",
            'error_message': "string",
            'matches': [],
            'status': 'success',
          }
        }}
      />   

      <CustomNode 
        data={{
          'label': 'Enrich Company via Apollo*', 
          'icon': 'apollo-logo', 
          "type": "input", 
          'color': 'green',
          'inputProps': [
            {
              'id': 'description',
              'label': 'description',
              'type': 'string',
              'component_type': 'text_input',
              'value': 'Get full company details',
            },
            {
              'id': 'domain',
              'label': 'domain',
              'type': 'string',
              'component_type': 'text_input',
              'value': '',
            },
          ],
          'outputProps': {
            response: "string",
          }
        }}
      />

      <CustomNode 
        data={{
          'label': 'Slack Message', 
          'icon': 'slack-logo', 
          'functionName': 'slack_send_message',
          "type": "input", 
          'color': 'green',
          'inputProps': [
            {
              'id': 'description',
              'label': 'description',
              'type': 'string',
              'component_type': 'text_input',
              'value': 'Notify on Slack',
            },
            {
              'id': 'person',
              'label': 'person',
              'type': 'string',
              'component_type': 'text_input',
              'value': '',
            },
            {
              'id': 'message',
              'label': 'message',
              'type': 'string',
              'component_type': 'large_text_input',
              'value': '',
            },
          ],
          'outputProps': {},
        }}
      />

        <CustomNode 
          data={{
            'label': 'Reveal Visitor Company', 
            'icon': 'clearbit-logo', 
            'functionName': 'bigpicture_reveal', 
            "type": "input", 
            'color': 'green',
            'inputProps': [
              {
                'id': 'description',
                'label': 'description',
                'type': 'string',
                'component_type': 'text_input',
                'value': 'Reveal with Clearbit',
              },
              {
                'id': 'ip_address',
                'label': 'IP Address',
                'type': 'string',
                'component_type': 'text_input',
                'value': '',
              }
            ],
            'outputProps': {
              visitor: "string"
            }
          }}
        />

        <CustomNode 
          data={{
            'label': 'URL',
            'functionName': 'return_url',
            'icon': 'link-logo', 
            "type": "input", 
            'color': 'green',
            'inputProps': [
              {
                'id': 'description',
                'label': 'description',
                'type': 'string',
                'component_type': 'text_input',
                'value': 'Start with URL',
              },
              {
                'id': 'url',
                'label': 'url',
                'type': 'string',
                'component_type': 'text_input',
                'value': '',
              }
            ],
            'outputProps': {
              url: "string",
            }

          }}
        />

        <CustomNode 
          data={{
            'label': 'Screenshot*', 
            'icon': 'scrape', 
            "type": "input", 
            'color': 'green',
            'inputProps': [
              {
                'id': 'description',
                'label': 'description',
                'type': 'string',
                'component_type': 'text_input',
                'value': 'Vision over screenshot',
              },
              {
                'id': 'url',
                'label': 'url',
                'type': 'string',
                'component_type': 'text_input',
                'value': '',
              },
              {
                'id': 'query',
                'label': 'query',
                'type': 'string',
                'component_type': 'text_input',
                'value': '',
              },
              {
                'id': 'output_schema',
                'label': 'output_schema',
                'type': 'string',
                'component_type': 'text_input',
                'value': '',
              }
            ],
            'outputProps': {}
          }}
        />
  
        <CustomNode 
          data={{
            'label': 'Scrape URL', 
            'functionName': 'scrape_page_both',
            'icon': 'scrape', 
            "type": "input", 
            'color': 'green',
            'inputProps': [
              {
                'id': 'description',
                'label': 'description',
                'type': 'string',
                'component_type': 'text_input',
                'value': 'Parse Page Text or HTML',
              },
              {
                'id': 'url',
                'label': 'url',
                'type': 'string',
                'component_type': 'large_text_input',
                'value': '',
              },
            ],
            'outputProps': {
              text: "string",
              html: "string",
            }
          }}
        />

        <CustomNode 
          data={{
            'label': 'Crawl Website*', 
            'icon': 'scrape', 
            "type": "input", 
            'color': 'green',
            'inputProps': [
              {
                'id': 'description',
                'label': 'description',
                'type': 'string',
                'component_type': 'text_input',
                'value': 'Parse Entire Website',
              },
              {
                'id': 'url',
                'label': 'url',
                'type': 'string',
                'component_type': 'text_input',
                'value': '1',
              },
              {
                'id': 'query',
                'label': 'query',
                'type': 'string',
                'component_type': 'text_input',
                'value': '1',
              },
              {
                'id': 'output_schema',
                'label': 'output_schema',
                'type': 'string',
                'component_type': 'text_input',
                'value': '',
              }
            ],
            'outputProps': {
              website_contents: [{
                page_url: "string",
                page_text: "string",
              }]
            }
          }}
        />

        <CustomNode 
          data={{
            'label': 'Search LinkedIn Posts*', 
            'icon': 'linkedin', 
            "type": "input", 
            'color': 'green',
            'inputProps': [
              {
                'id': 'description',
                'label': 'description',
                'type': 'string',
                'component_type': 'text_input',
                'value': 'Search LinkedIn Posts',
              },
              {
                'id': 'query',
                'label': 'query',
                'type': 'string',
                'component_type': 'text_input',
                'value': '',
              },
              {
                'id': 'max_results',
                'label': 'max_results',
                'type': 'string',
                'component_type': 'text_input',
                'value': '',
              },
              {
                'id': 'time_period',
                'label': 'time_period',
                'type': 'string',
                'component_type': 'text_input',
                'value': '',
              }
            ],
            'outputProps': {
              posts: []
            }

          }}
        />

        <CustomNode 
          data={{
            'label': 'LinkedIn Profile', 
            'functionName': 'fetch_linkedin_profile', // enrich_linkedin_activities_via_phantom_buster
            'icon': 'linkedin', 
            "type": "input", 
            'color': 'green',
            'inputProps': [
              {
                'id': 'description',
                'label': 'description',
                'type': 'string',
                'component_type': 'text_input',
                'value': 'Enrich LinkedIn Profile Data',
              },
              {
                'id': 'linkedin_url',
                'label': 'linkedin_url',
                'type': 'string',
                'component_type': 'large_text_input',
                'value': '',
              },
            ],
            'outputProps': {
              "profile": "string",
            }
          }}
        />

        <CustomNode 
          data={{
            'label': 'LinkedIn Group Profiles*', 
            'icon': 'linkedin', 
            "type": "input", 
            'color': 'green',
            'inputProps': [
              {
                'id': 'description',
                'label': 'description',
                'type': 'string',
                'component_type': 'text_input',
                'value': 'Get LinkedIn Profiles from Group',
              },
              {
                'id': 'group_url',
                'label': 'group_url',
                'type': 'string',
                'component_type': 'text_input',
                'value': '',
              }
            ],
            'outputProps': {
              profile: [{
                profile: 'string',
              }],
            },
          }}
        />


        <CustomNode 
          data={{
            'label': 'AI Inference', 
            'functionName': 'inference_maker', // TODO
            'icon': 'inference', 
            "type": "output", 
            'color': 'red',
            'inputProps': [
              {
                'id': 'description',
                'label': 'description',
                'type': 'string',
                'component_type': 'text_input',
                'value': 'Use Powerful Language Models',
              },
              {
                'id': 'model',
                'label': 'model',
                'type': 'string',
                'component_type': 'dropdown',
                'value': '',
                'enums': 'openai,anyscale,octo'
              },
              {
                'id': 'prompt',
                'label': 'prompt',
                'type': 'string',
                'component_type': 'large_text_input',
                'value': '',
              },
              {
                'id': 'text',
                'label': 'text',
                'type': 'string',
                'component_type': 'invisible',
                'value': '',
              },
              {
                'id': 'schema_output',
                'label': 'schema_output',
                'type': 'string',
                'component_type': 'large_text_input',
                'value': '',
              },
              {
                'id': 'retries',
                'label': 'retries',
                'type': 'string',
                'component_type': 'text_input',
                'value': '4',
              }
            ],
            'outputProps': { 
              response: "string",
            }
          }}
        />

        <CustomNode 
          data={{
            'label': 'Create Company Signal', 
            'functionName': 'add_company_signal',
            'icon': 'matryx-logo', 
            "type": "output", 
            'color': 'red',
            'inputProps': [
              {
                'id': 'description',
                'label': 'description',
                'type': 'string',
                'component_type': 'text_input',
                'value': 'Upload company specific info',
              },
              {
                'id': 'domain',
                'label': 'Domain',
                'type': 'string',
                'component_type': 'large_text_input',
                'value': '',
              },              
              {
                'id': 'signal_name',
                'label': 'Signal Name',
                'type': 'string',
                'component_type': 'large_text_input',
                'value': '',
              },
              {
                'id': 'signal_value',
                'label': 'Signal Value',
                'type': 'string',
                'component_type': 'large_text_input',
                'value': '',
              },
              {
                'id': 'signal_description',
                'label': 'Signal Description',
                'type': 'string',
                'component_type': 'text_input',
                'value': '',
              },
              {
                'id': 'signal_score',
                'label': 'Signal Score',
                'type': 'string',
                'component_type': 'text_input',
                'value': '',
              },
              // {
              //   'id': 'is_signal',
              //   'label': 'Signal Name',
              //   'type': 'string',
              //   'component_type': 'text_input',
              //   'value': 'true',
              // },
              // {
              //   'id': 'email',
              //   'label': 'email',
              //   'type': 'string',
              //   'component_type': 'invisible',
              //   'value': user?.email,
              // },
            ],
            'outputProps': {}
          }}
        />

        <CustomNode 
          data={{
            'label': 'Create Person Signal', 
            'functionName': 'add_person_signal',
            'icon': 'matryx-logo', 
            "type": "output", 
            'color': 'red',
            'inputProps': [
              {
                'id': 'description',
                'label': 'description',
                'type': 'string',
                'component_type': 'text_input',
                'value': 'Upload person specific info',
              },
              {
                'id': 'linkedin_url',
                'label': 'LinkedIn Url',
                'type': 'string',
                'component_type': 'large_text_input',
                'value': '',
              },              
              {
                'id': 'signal_name',
                'label': 'Signal Name',
                'type': 'string',
                'component_type': 'large_text_input',
                'value': '',
              },
              {
                'id': 'signal_value',
                'label': 'Signal Value',
                'type': 'string',
                'component_type': 'large_text_input',
                'value': '',
              },
              {
                'id': 'signal_description',
                'label': 'Signal Description',
                'type': 'string',
                'component_type': 'text_input',
                'value': '',
              },
              {
                'id': 'signal_score',
                'label': 'Signal Score',
                'type': 'string',
                'component_type': 'text_input',
                'value': '',
              },
            ],
            'outputProps': {}
          }}
        />

        <CustomNode 
          data={{
            'label': 'Get Company Segment*', 
            'functionName': 'get_company_segment',
            'icon': 'matryx-logo', 
            "type": "input", 
            'color': 'red',
            'inputProps': [
              {
                'id': 'description',
                'label': 'description',
                'type': 'string',
                'component_type': 'text_input',
                'value': 'Upload person specific info',
              },
              {
                'id': 'segment_name',
                'label': 'Segment Name',
                'type': 'string',
                'component_type': 'large_text_input',
                'value': '',
              },         
              {
                'id': 'email',
                'label': 'email',
                'type': 'string',
                'component_type': 'invisible',
                'value': user?.email,
              },
     
              // {
              //   'id': 'person_data',
              //   'label': 'Person Data',
              //   'type': 'string',
              //   'component_type': 'json_editable',
              //   'value': '',
              // },
              // {
              //   'id': 'is_signal',
              //   'label': 'Is Signal',
              //   'type': 'string',
              //   'component_type': 'text_input',
              //   'value': 'true',
              // },
            ],
            'outputProps': {}
          }}
        />

        <CustomNode 
          data={{
            'label': 'Get Person Segment*', 
            'functionName': 'get_person_segment',
            'icon': 'matryx-logo', 
            "type": "input", 
            'color': 'red',
            'inputProps': [
              {
                'id': 'description',
                'label': 'description',
                'type': 'string',
                'component_type': 'text_input',
                'value': 'Upload person specific info',
              },
              {
                'id': 'segment_name',
                'label': 'Segment Name',
                'type': 'string',
                'component_type': 'text_input',
                'value': '',
              },
              {
                'id': 'email',
                'label': 'email',
                'type': 'string',
                'component_type': 'invisible',
                'value': user?.email,
              },
              
              // {
              //   'id': 'person_data',
              //   'label': 'Person Data',
              //   'type': 'string',
              //   'component_type': 'json_editable',
              //   'value': '',
              // },
              // {
              //   'id': 'is_signal',
              //   'label': 'Is Signal',
              //   'type': 'string',
              //   'component_type': 'text_input',
              //   'value': 'true',
              // },
            ],
            'outputProps': {}
          }}
        />

        {/* <CustomNode 
          data={{
            'label': 'Spreadsheet Output*', 
            'icon': 'google-sheets', 
            "type": "output", 
            'color': 'red',
            'inputProps': [
              {
                'id': 'description',
                'label': 'description',
                'type': 'string',
                'component_type': 'text_input',
                'value': 'Output to Sheets',
              },
              {
                'id': 'spreadsheet_url',
                'label': 'spreadsheet_url',
                'type': 'string',
                'component_type': 'text_input',
                'value': '',
              },
              {
                'id': 'worksheet_name',
                'label': 'worksheet_name',
                'type': 'string',
                'component_type': 'text_input',
                'value': '',
              },
              {
                'id': 'mode',
                'label': 'mode',
                'type': 'string',
                'component_type': 'text_input',
                'value': 'append',
              },
              {
                'id': 'headers',
                'label': 'headers',
                'type': 'string',
                'component_type': 'text_input',
                'value': '',
              }
            ],
            'outputProps': {},

          }}
        /> */}


        <CustomNode 
          data={{
            'label': 'ForEach', 
            'functionName': 'for_each_function',
            'icon': 'foreach-icon', 
            "type": "output", 
            'color': 'red',
            'inputProps': [
              {
                'id': 'description',
                'label': 'description',
                'type': 'string',
                'component_type': 'text_input',
                'value': 'Iterate over each element in a list',
              },
              {
                'id': 'alist',
                'label': 'list',
                'type': 'string',
                'component_type': 'large_text_input_foreach',
                'value': '',
              },
            ],
            'outputProps': {
              item: "string",
            }

          }}
        />

        <CustomNode 
          data={{
            'label': 'If Statement', 
            'functionName': 'if_condition',
            'icon': 'if-statement', 
            "type": "output", 
            'color': 'red',
            'inputProps': [
              {
                'id': 'description',
                'label': 'description',
                'type': 'string',
                'component_type': 'text_input',
                'value': 'Iterate over each element in a list',
              },
              {
                'id': 'value_a',
                'label': 'Value A',
                'type': 'string',
                'component_type': 'large_text_input',
                'value': '',
              },              
              {
                'id': 'conditional',
                'label': 'Conditional',
                'type': 'string',
                'component_type': 'dropdown',
                'value': '',
                'enums': 'is, is not, includes, includes (ignore case), does not include, does not include (ignore case), less than, less than or equal to, greater than, greater than or equal to'
              },
              {
                'id': 'value_b',
                'label': 'Value B',
                'type': 'string',
                'component_type': 'large_text_input',
                'value': '',
              },
            ],
            'outputProps': {}

          }}
        />


        <CustomNode 
          data={{
            'label': 'Scheduled Run*', 
            'functionName': 'timed_start',
            'icon': 'clock', 
            "type": "input", 
            'color': 'green',
            'inputProps': [
              {
                'id': 'description',
                'label': 'Description',
                'type': 'string',
                'component_type': 'text_input',
                'value': 'Schedule the graph execution',
              },
              {
                'id': 'frequency',
                'label': 'Frequency',
                'type': 'string',
                'component_type': 'dropdown',
                'value': 'daily',
                'enums': 'minutely,hourly,daily,weekly,monthly',
              },
              {
                'id': 'interval',
                'label': 'Interval',
                'type': 'number',
                'component_type': 'number_input',
                'value': 1,
              },
              {
                'id': 'specific_time',
                'label': 'Specific Time',
                'type': 'string',
                'component_type': 'time_input',
                'value': '00:00',
              },
              {
                'id': 'timezone',
                'label': 'Timezone',
                'type': 'string',
                'component_type': 'text_input',
                'value': 'UTC',
              },
            ],
            'outputProps': {
              trigger_time: "string",
            }
          }}
        />


        <CustomNode 
          data={{
            'label': 'Update Table', 
            'functionName': 'insert_into_general_table', 
            'icon': 'google-sheets', 
            "type": "output", 
            'color': 'red',
            'inputProps': [
              {
                'id': 'description',
                'label': 'description',
                'type': 'string',
                'component_type': 'text_input',
                'value': 'Update Data Table',
              },
              {
                'id': 'table_name',
                'label': 'table_name',
                'type': 'string',
                'component_type': 'text_input',
                'value': '',
              },
              {
                'id': 'data',
                'label': 'data',
                'type': 'string',
                'component_type': 'large_text_input',
                'value': '',
              },
              {
                'id': 'merge_key',
                'label': 'merge_key',
                'type': 'string',
                'component_type': 'text_input',
                'value': '',
              }
            ],
            'outputProps': {},
          }}
        />

      </div>



    </aside>
  );
};

// Setting a default prop for `open`
NodesSidebar.defaultProps = {
  open: false
};

export default NodesSidebar;

const EditNodeSidebar = ({ open, node, updateNode, availableValues, getOutputPropsFromNodeId }) => {
  const [formData, setFormData] = useState({ inputProps: [] });
  const fake_nodeid_inputProp = {
    "id": "node_id",
    "label": "id",
    "type": "string",
    "component_type": "text",
    "value": node?.id,
  }
  const fake_output_props = {
    id: 'outputProps',
    label: 'Output Schema',
    type: 'string',
    component_type: 'json_output',
    value: node?.value,
  }


  // When the selectedNode prop changes, update the inputValues state
  useEffect(() => {
    if (node && node.data && node.data.inputProps) {
      setFormData(node.data);
    }
  }, [node]);

  const handleChange = (event, id) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      inputProps: prevFormData.inputProps.map(prop => 
        prop.id === id ? { ...prop, value: event.target.value } : prop
      ),
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateNode({ ...node, data: formData });
  };

  const makeWhitelist = (availableValues) => {
    return availableValues



    //  return [
    //     "dndnode_1.search_results",
    //     "dndnode_1.search_results[i]",
    //     "dndnode_1.search_results[i].link",
    // ]

  }

  const updateForm = (promptInputValue, id) => {
    // console.log("propId: ", id)
    // console.log("promptInputValue: ", promptInputValue)

    setFormData(prevFormData => ({
      ...prevFormData,
      inputProps: prevFormData.inputProps.map(prop => 
        prop.id === id ? { ...prop, value: promptInputValue } : prop
      ),
    }));

  }

  return (
    <aside className="edit-node-sidebar" style={{display: open ? '' : 'none'}}>
      <div className={"description " + (node && node.id) || "nonodeid"}>
        <h1>Edit Node</h1>
        {([fake_nodeid_inputProp, ...formData.inputProps, fake_output_props]).map((prop) => (
          prop.component_type === 'text_input' ? (
            <div className="node-edit-sidebar-group" key={prop.id}>
              <label className="attr-key">{prop.label}</label>
              <input
                type="text"
                className="attr-value"
                value={prop.value || ""}
                onChange={(event) => handleChange(event, prop.id)}
              />
            </div>
          ) : prop.component_type === 'large_text_input' ? (
            <div className="node-edit-sidebar-group" key={prop.id}>
              <label className="attr-key">{prop.label}</label>
              <MatryxSelect
                // className="attr-value"
                value={prop.value || ""}
                whitelist={makeWhitelist(availableValues)}
                onInputChange={ (promptInputValue) => {
                  updateForm(promptInputValue, prop.id)
                } }
              />
            </div>
          ) : prop.component_type === 'large_text_input_foreach' ? (
            <div className="node-edit-sidebar-group" key={prop.id}>
              <label className="attr-key">{prop.label}</label>
              <MatryxSelect
                // className="attr-value"
                value={prop.value || ""}
                whitelist={makeWhitelist(availableValues)}
                onInputChange={ (promptInputValue) => { 
                  // "{{node_1.search_results}}"

                  const inputString = promptInputValue.replace(/[{}]/g, '');

                  // Split the string into an array of parts divided by '.'
                  const parts = inputString.split('.');
                  const node_id = parts.shift();
                  const search_string = parts.join('.');

                  const node = getOutputPropsFromNodeId(node_id)

                  if (node) {
                    const outputProps = node.data.outputProps


                    const findValueInGraph = (graph, searchString) => {
                      // Split the search string into parts by '.' and '[]'
                      const parts = searchString.split(/\.|\[|\].?/).filter(Boolean);
                      let current = graph;
                    
                      // Iterate over each part to navigate through the graph
                      for (const part of parts) {
                        if (current[part] === undefined) {
                          // If the path does not exist, return undefined
                          return undefined;
                        }
                        current = current[part];
                      }
                    
                      return current;
                    }

                    const item_type = findValueInGraph(outputProps, search_string)[0]

                    // Update the outputProps based on the node type
                    setFormData(prevFormData => ({
                      ...prevFormData,
                      outputProps: item_type,
                    }));
                  }

                  updateForm(promptInputValue, prop.id)

                  // setFormData(prevFormData => ({
                  //   ...prevFormData,
                  //   outputProps: {
                  //     promptInputValue: "string",
                  //   },
                  // }));
                }}
              />
            </div>
          ) : prop.component_type === 'csv_file_input' ? (
            <div className="node-edit-sidebar-group" key={prop.id}>
              <label className="attr-key">{prop.label}</label>
              <CSVFileInput2
                defaultValue={ prop?.value ? JSON.parse(prop.value) : undefined }
                onInputChange={(data) => {
                  const csvData = JSON.stringify({response: data});
                  updateForm(csvData, prop.id);
                }}
                updateOutputProps={(newOutputProps) => {
                  setFormData(prevFormData => ({
                    ...prevFormData,
                    outputProps: {
                      response: newOutputProps
                    },
                  }));
                }}
              />
            </div>
          ) : prop.component_type === 'json_output' ? (
            <div className="node-edit-sidebar-group" key={prop.id}>
              <label className="attr-key">{prop.label}</label>
              <JSONOutput
                jsonData={formData.outputProps}
                editable={false}
              />
            </div>
          ) : prop.component_type === 'json_editable' ? (
            <div className="node-edit-sidebar-group" key={prop.id}>
              <label className="attr-key">{prop.label}</label>
              <JSONOutput
                jsonData={prop?.value ? JSON.parse(prop.value) : undefined }
                editable={true}
                onInputChange={(newValue) => {
                  updateForm(JSON.stringify(newValue), prop.id);
                }}
              />
            </div>
          ) : prop.component_type === 'invisible' ? (
            <div />
          ) : prop.component_type === 'dropdown' ? (
            <div className="node-edit-sidebar-group" key={prop.id}>
              <label className="attr-key">{prop.label}</label>
              <DropdownSelect
                defaultValue={prop.value || ''}
                enums={prop.enums ? prop.enums.split(',') : []}
                onInputChange={(newValue) => updateForm(newValue, prop.id)}
              />
            </div>
          ) : prop.component_type === 'text' ? (
            <div className="node-edit-sidebar-group" key={prop.id}>
              <label className="attr-key">{prop.label}</label>
              <div className="attr-value">{prop.value}</div>
            </div>
          ) : null
        ))}
        <button className="py-1 px-2 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-transparent hover:bg-indigo-600 border-indigo-600 text-indigo-600 hover:text-white rounded-md action-button" onClick={handleSubmit}>Submit</button>
      </div>
    </aside>
  );
};
// Setting a default prop for `open`
EditNodeSidebar.defaultProps = {
  open: false
};

export { EditNodeSidebar };
