import React, { useState, useEffect } from "react";
import { Link,useLocation } from "react-router-dom";

import LogoLight from '../assets/images/logo-light.png'
import ShreeIcon from '../assets/images/shree-276.png'

import SimpleBarReact from "simplebar-react";
import 'simplebar-react/dist/simplebar.min.css';

import {AiOutlineLineChart, PiBrowsersBold, AiOutlineAppstore, AiOutlineUser,TbBrandBlogger, AiOutlineShoppingCart,AiOutlineCamera,AiOutlineFile,MdOutlineEmail,LiaFileInvoiceDollarSolid, BiLogOutCircle,BiLayer,AiOutlineHome, IoIosSend, PiGraphLight, CiCircleList} from '../assets/icons/vander'

export default function Sidebar(){
    const [manu , setManu] = useState('');
    const [subManu , setSubManu] = useState('');
    const location = useLocation();

    useEffect(()=>{
        var current = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
        setManu(current)
        setSubManu(current)
    },[location.pathname.substring(location.pathname.lastIndexOf('/') + 1)])
    
    useEffect(() => {
        window.scrollTo(0, 0);
      }, [location]);

    return(
        <nav className="sidebar-wrapper sidebar-dark">
            <div className=" sidebar-content">
                <div className="sidebar-brand">
                    <Link to="/index"><img src={LogoLight} height="24" alt=""/></Link>
                </div>
            <SimpleBarReact style={{height:"calc(100% - 70px)"}}> 
                <ul className="sidebar-menu border-t border-white/10">
                    <li  className={manu === "index" ? "active" : ""}>
                        <Link to="/index"><AiOutlineHome className=" me-3 icon "/>Dashboard</Link>
                    </li>

                    <li  className={manu === "high-intent-leads" ? "active" : ""}>
                        <Link to="/high-intent-leads"><AiOutlineLineChart className=" me-3 icon "/>Inbound Signals</Link>
                    </li>

                    <li  className={manu === "outbound" ? "active" : ""}>
                        <Link to="/outbound"><IoIosSend className=" me-3 icon "/>Outbound Signals</Link>
                    </li>

                    {/* <li  className={manu === "social-signals" ? "active" : ""}>
                        <Link to="/ui-components4"><CiCircleList className=" me-3 icon "/>Social Signals</Link>
                    </li> */}

                    <li  className={manu === "workflow-builder" ? "active" : ""}>
                        <Link to="/workflow-builder"><PiGraphLight className=" me-3 icon "/>Workflow Builder</Link>
                    </li>

                    <li  className={manu === "workflow-library" ? "active" : ""}>
                        <Link to="/workflow-library"><AiOutlineAppstore className=" me-3 icon "/>Workflow Library</Link>
                    </li>


                    {/* <li  className={manu === "ui-components" ? "active" : ""}>
                        <Link to="/ui-components4"><BiLayer className=" me-3 icon "/>Usage</Link>
                    </li> */}

                </ul>
            </SimpleBarReact>
            </div>
        </nav>
        
    )
}