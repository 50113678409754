
import { Link } from 'react-router-dom'

import * as Icon from 'react-feather';
import {TbPhotoPlus, AiOutlineAndroid, RiBitCoinLine, MdOutlineCalendarMonth, AiOutlinePieChart, MdKeyboardArrowRight,AiOutlineExclamationCircle,AiOutlineCheckCircle, AiOutlineInfoCircle, MdKeyboardArrowDown, AiOutlineSetting, VscMention, MdKeyboardArrowLeft, BsHeartbreak,LiaClipboardCheckSolid, FiLock, BsExclamationTriangle,BsExclamationOctagon} from '../assets/icons/vander'

import {accordion} from '../data/data'
import html2canvas from 'html2canvas';
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import React, { useState, useRef, useEffect, useCallback } from "react";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  removeElements,
  Controls,
  ControlButton,
  Background
} from "react-flow-renderer";
import "./styles.css";
import InitialElements from "./initialElements";

import Chatbar from "./Chatbar.js";
import NodesSidebar, { EditNodeSidebar } from "./sidebar.js";
import { Node, InputNode, InferenceNode, DisplayNode, nodeStringTypeMap, nodeTypesDefinitons } from './Node';

let id = 0;
const getId = (elements) => {
    var maxNodeId = 0
    elements.forEach((element) => {
        if (element.id) {
          const nodeId = parseInt(element.id.split("_")[1], 10);
          if (!isNaN(nodeId) && nodeId > maxNodeId) {
            maxNodeId = nodeId;
          }
        }
      });
      

    return `dndnode_${maxNodeId+1}`
}


export default function WorkflowBuilder(){
    const captureRef = useRef(null);

    useEffect(() => {
        document.documentElement.setAttribute("dir", "ltr");
        document.documentElement.classList.add('light');
        document.documentElement.classList.remove('dark');
    }, []);

    const [isOpen, setOpen] = useState(0)
    const [isOpen1, setOpen1] = useState(0)
    const [activeIndex, setActiveIndex] = useState(0);
    const [modal, setModal] = useState(false)


    const reactFlowWrapper = useRef(null);
    const [editNodeSidebarOpen, setEditNodeSidebarOpen] = useState(false);
    const [showNodesSidebar, setShowNodesSidebarOpen] = useState(false);
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    const [elements, setElements] = useState(InitialElements);
    const [selectedNode, setSelectedNode] = useState(null);

    const [name, setName] = useState("New Workflow");
    const [description, setDescription] = useState("Describe what your workflow does here");
  

    const [runWorkflowButtonText, setRunWorkflowButtonText] = useState("Run Workflow")

    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();


    let production = false;
    let base_url = "";
  
    if (window.location.href.includes("matryx.co")) {
      production = true;
    }
  
    if (production) {
      base_url = "https://api.matryx.co";
    } else {
      base_url = "http://127.0.0.1:5000";
    }
  

    const onConnect = (params) => setElements((els) => addEdge(params, els));
    
    const onElementsRemove = (elementsToRemove) =>
      setElements((els) => removeElements(elementsToRemove, els));
    
    const onLoad = (_reactFlowInstance) => {
      _reactFlowInstance.fitView({ padding: 0.25 });
      setReactFlowInstance(_reactFlowInstance);
    }
  
    const onDragOver = (event) => {
      event.preventDefault();
      event.dataTransfer.dropEffect = "move";
    };
  
    const location = useLocation();
    const navigate = useNavigate()
    const params = new URLSearchParams(location.search);
    const workflowId = params.get('workflowId');
    
    useEffect(() => {
        const fetchData = async () => {
            if (workflowId) {
                console.log("--- log wokflow");

                try {
                    const token = await getAccessTokenSilently();

                    const response = await fetch(`${base_url}/fetch-workflow?workflowId=${workflowId}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },                                        });
                    const data = await response.json();
                    console.log("--- log wokflow 2222");
                    if (data.status === 'success') {
                        console.log("--- get workflow sucess")
                        const workflow = JSON.parse(data.workflow).elements
                        const name = data.name
                        setName(name)
                        const description = data.description
                        setDescription(description)
                        console.log(workflow)
                        setElements(workflow);
                    } else {
                        console.error('Error fetching workflow:', data.message);
                    }
                } catch (error) {
                    console.error('Error:', error);
                }
            }
        };

        fetchData();
    }, [location.search, getAccessTokenSilently, workflowId]);

    const onDrop = (event) => {
        // console.log("-- OnDrop")
        event.preventDefault();
        const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();

        const newNodeData = JSON.parse(event.dataTransfer.getData("application/reactflow"));
        const type = newNodeData['type']
        const label = newNodeData['label']
        const functionName = newNodeData['functionName']
        const color = newNodeData['color']
        const icon = newNodeData['icon']
        const inputProps = newNodeData['inputProps']
        const outputProps = newNodeData['outputProps']
        // console.log("label::::")
        
        const position = reactFlowInstance.project({
            x: event.clientX - reactFlowBounds.left,
            y: event.clientY - reactFlowBounds.top
        });
        // console.log("position::::")
        var newNode = {
            id: getId(elements),
            type,
            position,
            data: { 
                label,
                color,
                icon,
                functionName,
                inputProps,
                outputProps,
                fromCanvas: 1,
            }
        };
        // console.log("newNode::::")
        // console.log("nodeStringTypeMap: ", nodeStringTypeMap)
        // console.log("type: ", type)
        
        // TODO: Have default values in the definition of each Node component instead of dict[key] = key
        // Given the definitons of each nodeType (input, default, display - map those nodeProps to KeyValues that are editable)

        // 
        // Object.keys(nodeStringTypeMap[type]).forEach(key => {
        //     newNode.data[key] = key;
        // });

        // console.log("keye::::")
        
        // console.log("About to set elements")
        // console.log("Before: ", elements)
        
        setElements((es) => es.concat(newNode));
        // console.log("After: ", elements)

        // console.log("setElements::::")
        // console.log("setElements::::")
    };
  
    const onNodeClick = (event, element) => {
        setSelectedNode({});

        if (element.type !== 'edge') {
            setSelectedNode(element);
            // alert("onNodeClick")
        }

        setEditNodeSidebarOpen(!editNodeSidebarOpen)
    }
  
    const onNodesButtonClick = (event) => {
      setShowNodesSidebarOpen(!showNodesSidebar)
    }
  
    const onSave = useCallback(() => {
        const saveData = async (user) => {
            if (reactFlowInstance && isAuthenticated) {
                const token = await getAccessTokenSilently();

                const flow = reactFlowInstance.toObject();
        
                let workflowId = params.get('workflowId'); // Get the workflowId query parameter
                if (!workflowId) {
                    workflowId = ""
                    console.log("Workflow ID: ", workflowId);
                }
            
                const workflow = JSON.stringify(flow); // Stringify the elements
                console.log("---- " + workflow)
        
                fetch(`${base_url}/set-workflow`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,

                    },
                    body: JSON.stringify({ 
                        workflowId, 
                        workflow,
                        email: user?.email || "saleh.hindi.one@gmail.com",
                        name: name || "New Workflow88",
                        description: description || "88Describe your new workflow",
                    }),
                })
                .then(response => response.json())
                .then(data => {
                    if (data.status === 'success') {
                        console.log('Workflow saved successfully');
                        navigate(`/workflow-builder?workflowId=${data.workflow_id}`);
                    } else {
                    console.error('Error saving workflow:', data.message);
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
            }
        }
        saveData(user);
    }, [reactFlowInstance, isAuthenticated, name, description]);
  
    const updateNodeInElements = useCallback((updatedNode) => {
      setElements((els) =>
        els.map((el) => (el.id === updatedNode.id ? updatedNode : el))
      );
    }, []);  
  
    const setGraphElements = (data) => {
      setElements(data);
    };
  
    const onRunWorkflow = useCallback(() => {
        const runWorkflow = async () => {
            setRunWorkflowButtonText("Running Workflow")
            const token = await getAccessTokenSilently();
        
            let flow = {}
            if (reactFlowInstance) {
                flow = reactFlowInstance.toObject();
            }

            const workflow = JSON.stringify(flow); // Stringify the elements
    
            fetch(`${base_url}/run-workflow`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ workflow_graph: workflow }),
            })
            .then(response => response.json())
            .then(data => {  
                setRunWorkflowButtonText("Run Workflow")
                if (data.status === 'success') {
                    console.log('Workflow executed successfully');
                    console.log(data.data)
                } else {
                    console.error('Error executing workflow:', data.message);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
        }
        runWorkflow()
    
    }, [reactFlowInstance]);

    function flatten_output_props(output_props, parentPath = '') {
        let paths = [];
      
        Object.keys(output_props).forEach(key => {
          const path = parentPath ? `${parentPath}.${key}` : key;
      
          if (typeof output_props[key] === 'object' && output_props[key] !== null) {
            if (Array.isArray(output_props[key])) {
              // Add the array path itself
              paths.push(path);
    
              // Iterate over indices 0 to 9
              for (let index = 0; index < 10; index++) {
                const item = output_props[key][index];
                if (typeof item === 'object' && item !== null) {
                  // Recurse into array item if it's an object
                  paths = paths.concat(flatten_output_props(item, `${path}[${index}]`));
                } else if (item !== undefined) {
                  // Directly add the array item path if it's a primitive type
                  paths.push(`${path}[${index}]`);
                }
              }
            } else {
              // Recurse into nested objects
              paths = paths.concat(flatten_output_props(output_props[key], path));
            }
          } else {
            // Directly add the path for primitive types
            paths.push(path);
          }
        });
      
        return paths;
    }
      
    function findOutputPropsPaths(elements, targetNodeId) {
        if (!targetNodeId) {return []}

        // Separate nodes and edges from elements
        const nodes = elements.filter(el => el.data);
        const edges = elements.filter(el => el.source && el.target);

        // Helper function to find a node by its ID
        const findNodeById = (id) => nodes.find(node => node.id === id);

        // Recursive function to traverse the graph and collect outputProps
        function traverse(nodeId, visited = new Set(), outputProps = []) {
            const node = findNodeById(nodeId);
            if (!node || visited.has(nodeId)) {
                return outputProps;
            }
            visited.add(nodeId);
        
            // Exclude properties of the target node
            if (nodeId !== targetNodeId) {
                // Assuming outputProps structure is similar to inputProps
                if (node.data.outputProps) { // Replace inputProps with outputProps in your actual data






                    

                    let flattened_output_props = flatten_output_props(node.data.outputProps)
                    // let flatput_props = node.data.outputProps
                    flattened_output_props.forEach(prop => outputProps.push(`${nodeId}.${prop}`));
                }
            }
        
            // Find all edges ending at the current node
            const incomingEdges = edges.filter(edge => edge.target === nodeId);
            incomingEdges.forEach(edge => traverse(edge.source, visited, outputProps));
        
            return outputProps;
        }

        return traverse(targetNodeId);
    }

    const handleScreenshot = () => {
        html2canvas(captureRef.current).then(canvas => {
          const screenshotURL = canvas.toDataURL('image/png');
          const link = document.createElement('a');
          link.href = screenshotURL;
          link.download = 'screenshot.png';
          link.click();
        });
    };
    
    return(
        <div className="container-fluid relative px-3">
            <div className="layout-specing">
                <div className="md:flex justify-between items-center">
                    <div className="flex flex-col">
                    <h2
                        className="text-lg font-semibold"
                        contentEditable
                        onBlur={(e) => setName(e.target.textContent)}
                    >
                        {name}
                    </h2>
                    <h3
                        className="text-sm"
                        contentEditable
                        onBlur={(e) => {setDescription(e.target.textContent)}}
                    >
                        {description}
                    </h3>
                    </div>

                    <ul className="tracking-[0.5px] inline-flex items-center sm:mt-0 mt-3">
                        <li className="inline-block capitalize text-[14px] font-bold duration-500 dark:text-white/70 hover:text-indigo-600 dark:hover:text-white"><Link to="/index">Matryx</Link></li>
                        <li className="inline-block text-base text-slate-950 dark:text-white/70 mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight/></li>
                        <li className="inline-block capitalize text-[14px] font-bold text-indigo-600 dark:text-white" aria-current="page"><Link to="/workflow-builder">Workflow Builder</Link></li>
                        <li className="inline-block text-base text-slate-950 dark:text-white/70 mx-0.5 ltr:rotate-0  rtl:rotate-180"><MdKeyboardArrowRight/></li>
                        <h5 className="text-lg font-semibold">
                            {workflowId ? (<li className="inline-block capitalize text-[14px] font-bold text-indigo-600 dark:text-white" aria-current="page"><Link to={"/workflow-builder?workflowId=" + workflowId}>{workflowId}</Link></li>) : (<li className="inline-block capitalize text-[14px] font-bold dark:text-white" aria-current="page">Unsaved Workflow</li>)}
                        </h5>
                    </ul>
                </div>

                {/* Insert App  */}
                <div 
                    className="dndflow"
                    // ref={captureRef}
                >
                    <ReactFlowProvider>
                        <NodesSidebar 
                            open={showNodesSidebar}
                        />  

                        <div 
                            className="reactflow-wrapper"
                            ref={reactFlowWrapper}
                        >
                            <ReactFlow
                                elements={elements}
                                onConnect={onConnect}
                                onElementsRemove={onElementsRemove}
                                onLoad={onLoad}
                                onDrop={onDrop}
                                onDragOver={onDragOver}
                                snapToGrid={true}
                                onElementClick={onNodeClick}
                                onPaneClick={() => {
                                    setEditNodeSidebarOpen(false);
                                    setShowNodesSidebarOpen(false);
                                    setSelectedNode(undefined);
                                }}
                                nodeTypes={nodeTypesDefinitons}
                            >                            
                                <div 
                                    onClick={onSave} 
                                    class="frosted-button py-1 px-2 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-transparent hover:bg-indigo-600 border-indigo-600 text-indigo-600 hover:text-white rounded-md action-button" href="/ui-components">
                                    {workflowId ? "Update" : "Save"}
                                </div>
                                {/* <div 
                                    onClick={onSave} 
                                    class="py-1 px-2 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-transparent hover:bg-indigo-600 border-indigo-600 text-indigo-600 hover:text-white rounded-md action-button" href="/ui-components">
                                    Restore
                                </div> */}
                                <div 
                                    onClick={onNodesButtonClick} 
                                    className="frosted-button py-1 px-2 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-transparent hover:bg-indigo-600 border-indigo-600 text-indigo-600 hover:text-white rounded-md action-button" href="/ui-components">
                                    Nodes Sidebar
                                </div>
                                <div 
                                    onClick={onRunWorkflow} 
                                    className={`frosted-button py-1 px-2 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-transparent hover:bg-indigo-600 border-indigo-600 text-indigo-600 hover:text-white rounded-md action-button ${runWorkflowButtonText == "Run Workflow" ? '' : 'animated-ellipsis' }`} href="/ui-components">
                                    {runWorkflowButtonText}
                                </div>

                                <Controls />
                                <Background color="black" gap={16} />
                            </ReactFlow>
                        </div>

                        <EditNodeSidebar 
                            open={editNodeSidebarOpen}
                            node={selectedNode}
                            updateNode={updateNodeInElements}
                            availableValues={findOutputPropsPaths(elements, selectedNode?.id)}
                            getOutputPropsFromNodeId={(node_id) => {
                                const node = elements.find(el => el.id === node_id);
                                return node
                                
                            }}
                        />
                        {/* <Chatbar setGraphElements={setGraphElements} /> */}
                    </ReactFlowProvider>

                    </div>

            </div>
        </div>  
    )
}