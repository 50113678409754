import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";


const HighIntentTable = ({ spreadsheetData }) => {
    const [headers, setHeaders] = useState([]);
    const [rows, setRows] = useState([]);
    const [aggregateRows, setAggregateRows] = useState([]);
    const [expandedAggregRows, setExpandedAggregRows] = useState([]);

    const [originalCells, setOriginalCells] = useState({});

    const [iframeSrc, setIframeSrc] = useState('');
    const { user, getAccessTokenSilently, isAuthenticated, isLoadin, logout } = useAuth0();

    let production = false;
    let base_url = "";
  
    if (window.location.href.includes("matryx.co")) {
      production = true;
    }
  
    if (production) {
      base_url = "https://api.matryx.co";
    } else {
      base_url = "http://127.0.0.1:5000";
    }

    useEffect(() => {
        if (spreadsheetData && spreadsheetData.length > 0) {
            setHeaders(spreadsheetData[0]);
            setRows(spreadsheetData.slice(1));
        }

        setOriginalCells(rows.reduce((accumulator, currentValue) => {
            accumulator[currentValue[Object.keys(currentValue)]] = currentValue[Object.keys(currentValue)];
            return accumulator;
        }, {}));    
    }, [spreadsheetData]);

    useEffect(() => {
        // Function to fetch dashboard URL
        const fetchDashboardUrl = async () => {
            if (user) {
                const queryParams = new URLSearchParams({
                    type: 'inbound',
                    email: user.email // Assuming you want to use the user's email as a parameter
                });
                try {
                    const token = await getAccessTokenSilently();

                    const response = await fetch(`${base_url}/get-user-dashboard?${queryParams}`, {
                        headers: {
                            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
                        },  
                    });
                    if (response.ok) {
                        const data = await response.json();
                        setIframeSrc(data.dashboard_data); // Assuming 'dashboard_data' contains the URL
                    } else {
                        console.error('Failed to fetch dashboard URL');
                    }
                } catch (error) {
                    console.error('Error fetching dashboard URL:', error);
                }
            }
        };

        fetchDashboardUrl();
    }, [user]); // Re-fetch when user changes
 

    const leads = [
        { name: 'Saleh Hindi', link: '/lesson1', highIntent: true },
        { name: 'Ted Lasso', link: '/lesson1', highIntent: false },
        { name: 'Michael Bay', link: '/lesson1', highIntent: true },
    ]

    let retoolName = ""
    if (user && (user.email == 'saleh.hindi.one@gmail.com' || user.email == 'jon@magniv.io')) {
      retoolName = "PromptLayerMVP"
    }

    return (
        <>
            {iframeSrc ? (
                <iframe src={iframeSrc} width="100%" height="800px" />
            ) : (
                <div>Loading...</div>
            )}
        </>
    );


    
    if (headers.length == 0) {
        return (<div />)
    }


    const onClickRow = (e) => {
        if (e.currentTarget.className.includes("agg-row-class")) {
            console.log("hiii")
            var [aggregRowIdx, rowIdx] = e.currentTarget.getAttribute('data-key').split("___");
            aggregRowIdx = parseInt(aggregRowIdx)
            if (expandedAggregRows.includes(aggregRowIdx)) {
                const newList = expandedAggregRows.filter(item => item !== aggregRowIdx);
                setExpandedAggregRows(newList);
            } else {
                setExpandedAggregRows([...expandedAggregRows, aggregRowIdx]);
            }
        }
    }

    
    
    // Filter
    // Filter based on confidence score
    let filteredRows = rows.filter(row => parseFloat(row[5]) >= 0.9);
    // createAggregateRows()

    var summaryRowStats = headers.map((x) => { return "Company Name" })
    const aggColumnIdx = 0
    var aggRowsRaw = [[]]
    const sortedRows = filteredRows.sort((a, b) => a[aggColumnIdx].charCodeAt(0) - b[aggColumnIdx].charCodeAt(0));
    for(let i = 0; i < sortedRows.length; i++) {
        if ( i!=0 && sortedRows[i-1][aggColumnIdx]!=sortedRows[i][aggColumnIdx] ) {
            // In this case, look at the last aggreg group, insert a header at idx 0, and update lastStart


            // Calculate summary row stats
            let newSummaryRowStats = [...summaryRowStats];
            let rowInQuestion = aggRowsRaw[aggRowsRaw.length-1]
            // TODO: Secondary Sort and possibly secondar aggregate
            
            newSummaryRowStats[0] = rowInQuestion[0][0]
            newSummaryRowStats[1] = rowInQuestion[0][1]
            newSummaryRowStats[2] = rowInQuestion[0][2].slice(0, 40)
            newSummaryRowStats[3] = String(new Set(rowInQuestion.map(row => row[3])).size)
            newSummaryRowStats[4] = String(new Set(rowInQuestion.map(row => row[4])).size)
            newSummaryRowStats[5] = String(new Set(rowInQuestion.map(row => row[5])).size)
            newSummaryRowStats[6] = ""
            newSummaryRowStats[7] = ""
            newSummaryRowStats[8] = ""
            newSummaryRowStats[9] = ""
            // summaryRowStats[i] = sum/count unique, etc
            // summaryRowStats[i] = sum/count unique, etc
            aggRowsRaw[aggRowsRaw.length-1].unshift(
                newSummaryRowStats
            )

            // Make a new aggreg row and push current individual value to it
            aggRowsRaw.push([])
            aggRowsRaw[aggRowsRaw.length - 1].push(sortedRows[i]) 



        } else {

            aggRowsRaw[aggRowsRaw.length - 1].push(sortedRows[i]) 
        }


    }

    // TODO: Wrap this in a function call
    let newSummaryRowStats = [...summaryRowStats];
    let rowInQuestion = aggRowsRaw[aggRowsRaw.length-1]
    // TODO: Secondary Sort and possibly secondar aggregate
    
    newSummaryRowStats[0] = rowInQuestion[0][0]
    newSummaryRowStats[1] = rowInQuestion[0][1]
    newSummaryRowStats[2] = rowInQuestion[0][2].slice(0, 40)
    newSummaryRowStats[3] = String(new Set(rowInQuestion.map(row => row[3])).size)
    newSummaryRowStats[4] = String(new Set(rowInQuestion.map(row => row[4])).size)
    newSummaryRowStats[5] = String(new Set(rowInQuestion.map(row => row[5])).size)
    newSummaryRowStats[6] = ""
    newSummaryRowStats[7] = ""
    newSummaryRowStats[8] = ""
    newSummaryRowStats[9] = ""
    // summaryRowStats[i] = sum/count unique, etc
    // summaryRowStats[i] = sum/count unique, etc
    aggRowsRaw[aggRowsRaw.length-1].unshift(
        newSummaryRowStats
    )

    ///////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////


    // TODO: This does not insert a final aggreg row. Why?

    

    // TODO Should be called new component
    // Aggreg unique visitors - transform row based on func and hardcoded header type
    // Filter to remove bad data on frontend
    // Default sort
    // 


    console.log("rendering")
    console.log("expandedAggregRows: ", expandedAggregRows)
    return (
        <>


    {/* Test commit */}
    <div 
        // style={{width: '2000px'}} 
        className="p-5 border-t overflow-x-scroll border-gray-100 dark:border-slate-800"
    >
      <div className="relative overflow-x-auto block w-full bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
        <table key={expandedAggregRows.length} className="w-full text-start">
            <thead>
                <tr>
                    {headers.map((header, index) => (
                        <th key={index} className="px-4 py-5 text-start">{index == 5 ? "Visitors" : header}</th>
                    ))}
                </tr>
            </thead>


            <tbody className="table-text">
                {/* For each aggregate row group */}
                {aggRowsRaw.map((aggregRow, aggregRowIdx) => (
                    <>

                        {/* Iterate over individual row, incl aggreg row */}
                        {aggregRow.map((row, rowIdx) => (
                            <tr 
                            key={aggregRowIdx + "___" + rowIdx + "___" + expandedAggregRows.length} 
                            data-key={aggregRowIdx + "___" + rowIdx} 
                            className={"border-t border-gray-100 dark:border-gray-700 " + (rowIdx==0 ? "agg-row-class" : "")}
                            onClick={onClickRow}
                            >
                                {(expandedAggregRows.includes(aggregRowIdx) || rowIdx==0) && row.map((cell, cellIndex) => (
                                    // Map each column to the table
                                    <td key={aggregRowIdx + "___" + rowIdx + "___" + cellIndex} className="small-text">
                                        <span title={originalCells[cell]}>{cell.substr(0, 100)}</span>
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </>
                ))}

            </tbody>


        </table>
      </div>
    </div>
    </>
  );
};

export default HighIntentTable;
