import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";

import { galleryData, images } from "../../data/data";
import portfolio1 from '../../assets/images/workflow_4.png'


import {MdKeyboardArrowRight, MdKeyboardArrowLeft} from '../../assets/icons/vander'

import Lightbox from 'react-18-image-lightbox';
import "react-18-image-lightbox/style.css"
import { useAuth0 } from "@auth0/auth0-react";

export default function Gallerytwo(){
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [workflows, setWorkflows] = useState([]);
    

    useEffect(() => {
        document.documentElement.setAttribute("dir", "ltr");
        document.documentElement.classList.add('light');
        document.documentElement.classList.remove('dark');
    }, []);

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isOpen, setisOpen] = useState(false);

    let production = false;
    let frontend_base_url = "";
    let backend_base_url = "";
  
    if (window.location.href.includes("matryx.co")) {
      production = true;
    }
  
    if (production) {
      frontend_base_url = "https://app.matryx.co";
      backend_base_url = "https://api.matryx.co";
    } else {
      frontend_base_url = "http://localhost:3000";
      backend_base_url = "http://127.0.0.1:5000";
    }
  
    useEffect(() => {
        const fetchWorkflows = async () => {
          if (isAuthenticated) {
            try {
              const token = await getAccessTokenSilently();
              const response = await fetch(`${backend_base_url}/fetch-workflows?email=${user.email}`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
              const data = await response.json();
              if (data.status === 'success') {
                setWorkflows(data.workflows);
              } else {
                console.error('Error fetching workflows:', data.message);
              }
            } catch (error) {
              console.error('Error:', error);
            }
          }
        };
      
        fetchWorkflows();
      }, [isAuthenticated, getAccessTokenSilently, user]);

    const handleMovePrev = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + images.length - 1) % images.length);
    };

    const handleMoveNext = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };
    const handleImageClick = (index) => {
        setCurrentImageIndex(index);
        setisOpen(true);

        const workflowId = 1234567; // Replace this with the actual workflowId
        // history.push(`/workflow-builder?workflowId=${workflowId}`);    
    };
    const currentImage = images[currentImageIndex];

    
    const matchCategory = (category) => {
        setSelectedCategory(category);
    };


    // const filteredData = selectedCategory
    //     ? galleryData.filter((item) => item.category === selectedCategory)
    //     : galleryData;

    return(
        <div className="container-fluid relative px-3">
            <div className="layout-specing">
                <div className="md:flex justify-between items-center">
                    <h5 className="text-lg font-semibold">Workflow Library</h5>

                    <ul className="tracking-[0.5px] inline-flex items-center sm:mt-0 mt-3">
                        <li className="inline-block capitalize text-[14px] font-bold duration-500 dark:text-white/70 hover:text-indigo-600 dark:hover:text-white"><Link to="/">Matryx</Link></li>
                        <li className="inline-block text-base text-slate-950 dark:text-white/70 mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight/></li>
                        <li className="inline-block capitalize text-[14px] font-bold text-indigo-600 dark:text-white" aria-current="page">Gallery</li>
                    </ul>
                </div>

                <div className="grid grid-cols-1 items-center mt-6 gap-6">
                    <div className="filters-group-wrap">
                        <div className="filters-group">
                            <ul className="list-none container-filter-border-bottom filter-options space-x-3">
                                <li className={`${selectedCategory === null ? 'active' : ''} inline-block font-semibold mb-3 cursor-pointer relative border-b border-transparent text-slate-400 duration-500 `} data-group="all" onClick={() => matchCategory(null)}>All</li>
                                <li className={`${selectedCategory === 'sales' ? 'active' : ''} inline-block font-semibold mb-3 cursor-pointer relative border-b border-transparent text-slate-400 duration-500 `} data-group="sales" onClick={() => matchCategory('sales')}>Sales</li>
                                <li className={`${selectedCategory === 'marketing' ? 'active' : ''} inline-block font-semibold mb-3 cursor-pointer relative border-b border-transparent text-slate-400 duration-500 `} data-group="marketing" onClick={() => matchCategory('marketing')}>Marketing</li>
                                <li className={`${selectedCategory === 'competitors' ? 'active' : ''} inline-block font-semibold mb-3 cursor-pointer relative border-b border-transparent text-slate-400 duration-500 `} data-group="competitors" onClick={() => matchCategory('competitors')}>Competitors</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div id="grid" className="grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 mt-6 gap-6">
                    {workflows.map((item, index) => {
                        return (
                            <div className="picture-item cursor-pointer" key={index} style={{ height: '200px', width: '200px' }}>
                                <Link to={`${frontend_base_url}/workflow-builder?workflowId=` + item.id} onClick={() => handleImageClick(item.id)}>
                                    <div className="relative block rounded-md duration-500">
                                        {/* <img src={portfolio1} className="w-full h-full object-cover" alt="" /> */}
                                        <div className="absolute bottom-0 left-0 right-0 bg-white dark:bg-slate-900 p-4 rounded shadow dark:shadow-gray-700" style={{height: '195px'}}>
                                            <h5 className="text-lg duration-500 font-medium">{item.name}</h5>
                                            <h6 className="text-slate-400">{item.description}</h6>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        );
                    })}
                </div>
                {isOpen && (
                    <Lightbox
                        mainSrc={currentImage}
                        prevSrc={images[(currentImageIndex + images.length - 1) % images.length]}
                        nextSrc={images[(currentImageIndex + 1) % images.length]}

                        onCloseRequest={() => setisOpen(false)}
                        onMovePrevRequest={handleMovePrev}
                        onMoveNextRequest={handleMoveNext}
                    />
                    )}

                {/* Pagination Page */}
                {/* <div className="flex justify-end mt-6">
                    <nav aria-label="Page navigation example">
                        <ul className="inline-flex items-center -space-x-px">
                            <li>
                                <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-s-lg hover:text-white border border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600">
                                   <MdKeyboardArrowLeft className="text-[20px] rtl:rotate-180 rtl:-mt-1"/>
                                </Link>
                            </li>
                            <li>
                                <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600">1</Link>
                            </li>
                            <li>
                                <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600">2</Link>
                            </li>
                            <li>
                                <Link to="#" aria-current="page" className="z-10 w-[40px] h-[40px] inline-flex justify-center items-center text-white bg-indigo-600 border border-indigo-600">3</Link>
                            </li>
                            <li>
                                <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600">4</Link>
                            </li>
                            <li>
                                <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600">5</Link>
                            </li>
                            <li>
                                <Link to="#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-e-lg hover:text-white border border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600">
                                    <MdKeyboardArrowRight className="text-[20px] rtl:rotate-180 rtl:-mt-1"/>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div> */}
            </div>
        </div>
    )
}