import * as Icon from 'react-feather'

import React from "react"
import { Link } from "react-router-dom"
import CountUp from 'react-countup';
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import {HiArrowTrendingDown,HiArrowTrendingUp, FiArrowRight} from '../assets/icons/vander'
import {IoAnalyticsSharp} from 'react-icons/io5'

import { profitData } from "../data/data";

export default function DataStates(){
    let base_url = "";
    if (window.location.href.includes("matryx.co")) {
        base_url = "https://api.matryx.co";
    } else {
        base_url = "http://127.0.0.1:5000";
    }

    const { getAccessTokenSilently, user } = useAuth0();
    const [dashboardData, setDashboardData] = useState(null);
    
    useEffect(() => {
      const fetchData = async () => {
        try {
          const token = await getAccessTokenSilently();
          const userEmail = encodeURIComponent(user.email); // Ensure the email is URL-safe
          const response = await fetch(`${base_url}/get-dashboard?email=${userEmail}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (!response.ok) throw new Error('Network response was not ok');
          const data = await response.json();
          setDashboardData(data);
        } catch (error) {
          console.error('Error fetching dashboard data:', error);
        }
      };
    
      fetchData();
    }, [getAccessTokenSilently, user]);

    // console.log("----")
    // console.log(dashboardData)

    if (!dashboardData) {
        return (<div></div>)
    }

    const iconMap = {
        "Icon.Users": Icon.Users,
        "Icon.Home ": Icon.Home ,
        "Icon.UserCheck": Icon.UserCheck,
        "Icon.Home": Icon.Home,
        "Icon.DollarSign": Icon.DollarSign,
    }

    return(
        <div className="grid xl:grid-cols-5 md:grid-cols-3 grid-cols-1 mt-6 gap-6">
            {dashboardData.data.map((item,index)=>{
                const Icons = iconMap[item.icon]
                return(
                    <div className="relative overflow-hidden rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900" key={index}>
                        <div className="p-5 flex items-center">
                            <span className="flex justify-center items-center rounded-md h-14 w-14 min-w-[56px] bg-indigo-600/5 dark:bg-indigo-600/10 shadow shadow-indigo-600/5 dark:shadow-indigo-600/10 text-indigo-600">
                                <Icons className="h-5 w-5"/>
                            </span>

                            <span className="ms-3">
                                <span className="text-slate-400 font-semibold block">{item.title}</span>
                                <span className="flex items-center justify-between mt-1">
                                    <span className="text-xl font-semibold"><span className="counter-value"><CountUp start={0} end={item.amount}/></span></span>
                                    {/* {item?.loss ?  <span className="text-red-600 text-sm ms-1 font-semibold flex items-center"><HiArrowTrendingDown className="me-1"/>{item?.loss}</span> : ''} */}
                                    {/* {item?.profit ?  <span className="text-emerald-600 text-sm ms-1 font-semibold flex items-center"><HiArrowTrendingUp className="me-1"/>{item?.profit}</span> : ''} */}
                                    {/* {item?.null ?  <span className="text-slate-400 text-sm ms-1 font-semibold flex items-center"><IoAnalyticsSharp className="me-1"/>{item?.null}</span> : ''} */}
                                    
                                </span>
                            </span>
                        </div>

                        <div className="px-5 py-4 bg-gray-50 dark:bg-slate-800">
                            <Link to="/high-intent-leads" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:transition-all after:duration-500 text-indigo-600 dark:text-white/70 hover:text-indigo-600 dark:hover:text-white after:bg-indigo-600 dark:after:bg-white duration-500">View data <FiArrowRight className="ms-1"/></Link>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}