import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react"; // Import this only if authentication is needed

import SimpleBarReact from "simplebar-react";
import 'simplebar-react/dist/simplebar.min.css';

import {FiArrowRight} from '../../assets/icons/vander'
import { orderData } from "../../data/data";

export default function Order(){
    const [orderData, setOrderData] = useState([]);
    const { getAccessTokenSilently, user } = useAuth0(); // Use this only if authentication is needed

    useEffect(() => {
        const fetchOrderData = async () => {
            if (user?.email) {
                const userEmail = user.email;
                let base_url = window.location.href.includes("matryx.co") ? "https://api.matryx.co" : "http://127.0.0.1:5000";
                // Include the user's email in the endpoint URL as a query parameter
                const endpoint = `${base_url}/high-intent-companies/?email=${encodeURIComponent(userEmail)}`;
                
                try {
                    const token = await getAccessTokenSilently(); // Use this only if authentication is needed
                    const response = await fetch(endpoint, {
                        headers: {
                            Authorization: `Bearer ${token}`, // Include this only if authentication is needed
                        },
                    });

                    if (response.ok) {
                        const data = await response.json();
                        setOrderData(data); // Assuming the API returns an array of orders
                    } else {
                        console.error('Failed to fetch order data');
                    }
                } catch (error) {
                    console.error('Error fetching order data:', error);
                }
            }
        };

        fetchOrderData();
    }, [getAccessTokenSilently, user]); // Add user.email to the dependency array to refetch when the email changes


    return(
        <>
            <div className="xl:col-span-9 lg:col-span-12">
                <div className="relative overflow-hidden rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
                    <div className="p-6 flex items-center justify-between border-b border-gray-100 dark:border-gray-800">
                        <h6 className="text-lg font-semibold">High Intent Accounts</h6>
                        
                        <Link to="/outbound" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:transition-all after:duration-500 text-slate-400 dark:text-white/70 hover:text-indigo-600 dark:hover:text-white after:bg-indigo-600 dark:after:bg-white duration-500">View Accounts <FiArrowRight className="ms-1 text-sm"/></Link>
                    </div>
                    <SimpleBarReact className="max-h-[400px]"> 
                        <div className="relative overflow-x-auto block w-full">
                            <table className="w-full text-start">
                                <thead className="text-base">
                                    <tr>
                                        <th className="text-start font-semibold text-[15px] p-4 min-w-[100px]">Name</th>
                                        <th className="text-start font-semibold text-[15px] p-4 min-w-[108px]">Visitors</th>
                                        <th className="text-start font-semibold text-[15px] p-4 min-w-[108px]">Last Visit</th>
                                        <th className="text-end font-semibold text-[15px] p-4 min-w-[128px]">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orderData.map((item, index) => {
                                        return(
                                            <tr key={index}>
                                                <td className="text-start border-t border-gray-100 dark:border-gray-800 p-4 flex">
                                                    {item.logo_url && <img src={item.logo_url} style={{
                                                        width: '16px',
                                                        height: '16px',
                                                        alignSelf: 'center',
                                                        borderRadius: '7px',
                                                        marginRight: '5px',

                                                    }} />}
                                                    {item.name}
                                                </td>
                                                <td className="text-start border-t border-gray-100 dark:border-gray-800 p-4">
                                                    <span className="text-slate-400">{item.visitors}</span>
                                                </td>
                                                <td className="text-start border-t border-gray-100 dark:border-gray-800 p-4">
                                                    <span className="text-slate-400">{item.last_visit}</span>
                                                </td>
                                                <td className="text-end border-t border-gray-100 dark:border-gray-800 p-4">
                                                    {item.tags.includes('expansion_news') ?   <span className="bg-emerald-600/10 dark:bg-emerald-600/20 border border-emerald-600/10 dark:border-emerald-600/20 text-emerald-600 text-[12px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">Expansion News</span> : ''}
                                                    {item.tags.includes('talks_about_cyber') ?   <span className="bg-indigo-600/10 dark:bg-indigo-600/20 border border-indigo-600/10 dark:border-indigo-600/20 text-indigo-600 text-[12px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">Talks about Cyber</span> : ''}
                                                    {item.tags.includes('talks_about-assisted-living') ?   <span className="bg-indigo-600/10 dark:bg-indigo-600/20 border border-indigo-600/10 dark:border-indigo-600/20 text-indigo-600 text-[12px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">Talks about Assisted Living</span> : ''}
                                                    {item.tags.includes('recent_visit') ?   <span className="bg-emerald-600/10 dark:bg-emerald-600/20 border border-emerald-600/10 dark:border-emerald-600/20 text-emerald-600 text-[12px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">Recent Visit</span> : ''}
                                                    {/* {item.tags.includes('recent_visit') ?   <span className="bg-indigo-600/10 dark:bg-indigo-600/20 border border-indigo-600/10 dark:border-indigo-600/20 text-indigo-600 text-[12px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">Recent Visit</span> : ''} */}
                                                    {item.tags.includes('many_visitors') ?   <span className="bg-indigo-600/10 dark:bg-indigo-600/20 border border-indigo-600/10 dark:border-indigo-600/20 text-indigo-600 text-[12px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">Many Visitors</span> : ''}
                                                    {/* {item.tags.includes('talks_about_cyber') ?   <span className="bg-indigo-600/10 dark:bg-indigo-600/20 border border-indigo-600/10 dark:border-indigo-600/20 text-indigo-600 text-[12px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">Talks about Cyber</span> : ''} */}
                                                    {/* {item.tags.includes('High Intent') ?   <span className="bg-red-600/10 dark:bg-red-600/20 border border-red-600/10 dark:border-red-600/20 text-red-600 text-[12px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">{item.tags}</span> : ''} */}
                                                    {/* {item.tags.includes('None') ?   <span className="bg-gray-500/5 border border-gray-500/5 text-gray-500 text-[12px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">{item.tags}</span> : ''} */}
         
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </SimpleBarReact>
                </div>
            </div>
        </>
    )
}