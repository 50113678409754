import React,{useState, useEffect} from "react";

import { Link, useLocation } from "react-router-dom";
import * as Icon from 'react-feather';
import {TbPhotoPlus, AiOutlineAndroid, RiBitCoinLine, MdOutlineCalendarMonth, AiOutlinePieChart, MdKeyboardArrowRight,AiOutlineExclamationCircle,AiOutlineCheckCircle, AiOutlineInfoCircle, MdKeyboardArrowDown, AiOutlineSetting, VscMention, MdKeyboardArrowLeft, BsHeartbreak,LiaClipboardCheckSolid, FiLock, BsExclamationTriangle,BsExclamationOctagon} from '../assets/icons/vander'

import {accordion} from '../data/data'
import { CompactTable } from '@table-library/react-table-library/compact';

import { useTheme } from '@table-library/react-table-library/theme';
import { getTheme } from '@table-library/react-table-library/baseline';

// import { nodes } from '../data';

import HighIntentTable from "./HighIntentTable";
import { useAuth0 } from "@auth0/auth0-react";


const headers = ['firstName', 'lastName', 'domain', 'email', 'linkedin_url', 'emailBody']


// TODO:
// Hardcode Sheets URL
// Set data = sheets
// Dynamically create leads var from sheets
// Every 2 second, fetch the latest sheet
// Dynamically set HighIntentTable.js based on sheet

export default function HighIntentLeads(){
    const [leadData, setLeadData] = useState([]);
    const [spreadsheetData, setSpreadsheetData] = useState([]);
    
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const sheetId = queryParams.get('sheetId');

    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

    let production = false;
    let base_url = "";
  
    if (window.location.href.includes("matryx.co")) {
      production = true;
    }
  
    if (production) {
      base_url = "https://api.matryx.co";
    } else {
      base_url = "http://127.0.0.1:5000";
    }
  

    // const [headers, setHeaders] = useState([]);
    // const [rowData, setRowData] = useState([]);

    useEffect(() => {
        // Not being used - this is for darkmode
        // document.documentElement.setAttribute("dir", "ltr");
        // document.documentElement.classList.add('light');
        // document.documentElement.classList.remove('dark');


        const fetchData = async () => {


            try {
                if (!sheetId || sheetId === 'test123') {
                    // Load your fake data here
                    const fakeData = [
                        ["Jorge", "Torres", "mindsdb.com", "jorge@mindsdb.com", "http://www.linkedin.com/in/torresjorge", "emailBody", "Jorge", "Torres", "mindsdb.com", "jorge@mindsdb.com", "http://www.linkedin.com/in/torresjorge", "emailBody"],
                        ["Patrick", "Ravensburg", "productlane.com", "patrick@productlane.io", "http://www.linkedin.com/in/pgvr", "emailBody", "Jorge", "Torres", "mindsdb.com", "jorge@mindsdb.com", "http://www.linkedin.com/in/torresjorge", "emailBody"],
                        ["Andrew", "Israel", "propelauth.com", "andrew@propelauth.com", "http://www.linkedin.com/in/andrew-israel-457751b8", "emailBody", "Jorge", "Torres", "mindsdb.com", "jorge@mindsdb.com", "http://www.linkedin.com/in/torresjorge", "emailBody"],
                        ["Robert", "Tran", "inkeep.com", "robert@inkeep.com", "http://www.linkedin.com/in/robert-tran-profile", "emailBody", "Jorge", "Torres", "mindsdb.com", "jorge@mindsdb.com", "http://www.linkedin.com/in/torresjorge", "emailBody"],
                        ["Adam", "B.", "hypermatic.com", "adam@hypermatic.com", "http://www.linkedin.com/in/adamdangerbrock", "emailBody", "Jorge", "Torres", "mindsdb.com", "jorge@mindsdb.com", "http://www.linkedin.com/in/torresjorge", "emailBody"],
                        ["Simon", "Rohrbach", "plain.com", "simon.rohrbach@plain.com", "http://www.linkedin.com/in/simonrohrbach", "emailBody", "Jorge", "Torres", "mindsdb.com", "jorge@mindsdb.com", "http://www.linkedin.com/in/torresjorge", "emailBody"],
                        ["Andrew", "Bastin", "hoppscotch.io", "andrew@hoppscotch.io", "http://www.linkedin.com/in/andrew-bastin-7456771a8", "emailBody", "Jorge", "Torres", "mindsdb.com", "jorge@mindsdb.com", "http://www.linkedin.com/in/torresjorge", "emailBody"],
                        // Repeat the rows as needed
                    ];                    
                    setSpreadsheetData(fakeData);
                } else {
                    const token = await getAccessTokenSilently();


                    const resp = fetch(`${base_url}/fetch-google-sheet-data`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify({ 
                            sheetId, 
                        }),
                    })
                        .then(response => response.json())
                        .then(data => {  
                        if (data.status === 'success') {

                            console.log('Workflow executed successfully');
                            console.log(data)
                            setSpreadsheetData(data.data)

                        } else {
                            console.error('Error executing workflow:', data.message);
                        }
                        })
                        .catch((error) => {
                        console.error('Error:', error);
                        });
                
                    // const jsonData = await response.json();

                    // const returnedData = jsonData.data
                    // setLeadData(returnedData);

                    // if (returnedData && typeof(returnedData) == typeof([]) && returnedData.length > 1) {
                    //     setHeaders(returnedData[0])
                    //     setRowData(returnedData.slice(1))
                    // }
                }
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };
    
        fetchData();
        // const ddddata = data
        const intervalId = setInterval(fetchData, 10000); // fetch data every 3 seconds
        
        return () => clearInterval(intervalId);    
    }, []);


    return(
        <div className="container-fluid relative px-3">
            <div className="layout-specing">
                {/* <div className="md:flex justify-between items-center">
                    <h5 className="text-lg font-semibold">Inbound Signals</h5>

                    <ul className="tracking-[0.5px] inline-flex items-center sm:mt-0 mt-3">
                        <li className="inline-block capitalize text-[14px] font-bold duration-500 dark:text-white/70 hover:text-indigo-600 dark:hover:text-white"><Link to="/index">Matryx</Link></li>
                        <li className="inline-block text-base text-slate-950 dark:text-white/70 mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight/></li>
                        <li className="inline-block capitalize text-[14px] font-bold text-indigo-600 dark:text-white" aria-current="page">Components</li>
                    </ul>
                </div> */}

                <div 
                    // style={{'width': '2000px'}}
                >
                    <HighIntentTable spreadsheetData={ spreadsheetData } />
                </div>


            </div>
        </div>  
    )
}