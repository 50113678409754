import React,{useState, useEffect} from "react";

import { Link } from 'react-router-dom'

import * as Icon from 'react-feather';
import {TbPhotoPlus, AiOutlineAndroid, RiBitCoinLine, MdOutlineCalendarMonth, AiOutlinePieChart, MdKeyboardArrowRight,AiOutlineExclamationCircle,AiOutlineCheckCircle, AiOutlineInfoCircle, MdKeyboardArrowDown, AiOutlineSetting, VscMention, MdKeyboardArrowLeft, BsHeartbreak,LiaClipboardCheckSolid, FiLock, BsExclamationTriangle,BsExclamationOctagon} from '../assets/icons/vander'

import {accordion} from '../data/data'
export default function Blank(){

    useEffect(() => {
        document.documentElement.setAttribute("dir", "ltr");
        document.documentElement.classList.add('light');
        document.documentElement.classList.remove('dark');
    }, []);

    const [isOpen, setOpen] = useState(0)
    const [isOpen1, setOpen1] = useState(0)
    const [activeIndex, setActiveIndex] = useState(0);
    const [modal, setModal] = useState(false)

   

    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            setActiveIndex(0);
        } else {
            setActiveIndex(index);
        }
    }
    const handleTabClick = (index) => {
        setOpen(index);
    };
    const handleTabClick1 = (index) => {
        setOpen1(index);
    };

    const [manu1, setManu1] = useState(false)
    const [manu2, setManu2] = useState(false)
    const [manu3, setManu3] = useState(false)
    const [manu4, setManu4] = useState(false)
    const [manu5, setManu5] = useState(false)
    const [manu6, setManu6] = useState(false)
    const [manu7, setManu7] = useState(false)
    const [manu8, setManu8] = useState(false)
    const [manu9, setManu9] = useState(false)
    const [manu10, setManu10] = useState(false)
    const [manu11, setManu11] = useState(false)
    const [manu12, setManu12] = useState(false)
    const [manu13, setManu13] = useState(false)
    const [manu14, setManu14] = useState(false)
    const [manu15, setManu15] = useState(false)
    const [manu16, setManu16] = useState(false)
    const [manu17, setManu17] = useState(false)
    const [manu18, setManu18] = useState(false)
    const [manu19, setManu19] = useState(false)
    const [manu20, setManu20] = useState(false)

    useEffect(()=>{
        let handleClick1 = () =>{setManu1(false)}
        let handleClick2 = () =>{setManu2(false)}
        let handleClick3 = () =>{setManu3(false)}
        let handleClick4 = () =>{setManu4(false)}
        let handleClick5 = () =>{setManu5(false)}
        let handleClick6 = () =>{setManu6(false)}
        let handleClick7 = () =>{setManu7(false)}
        let handleClick8 = () =>{setManu8(false)}
        let handleClick9 = () =>{setManu9(false)}
        let handleClick10 = () =>{setManu10(false)}
        let handleClick11 = () =>{setManu11(false)}
        let handleClick12 = () =>{setManu12(false)}
        let handleClick13 = () =>{setManu13(false)}
        let handleClick14 = () =>{setManu14(false)}
        let handleClick15 = () =>{setManu15(false)}
        let handleClick16 = () =>{setManu16(false)}
        let handleClick17 = () =>{setManu17(false)}
        let handleClick18 = () =>{setManu18(false)}
        let handleClick19 = () =>{setManu19(false)}
        let handleClick20 = () =>{setManu20(false)}

        document.addEventListener("mousedown", handleClick1);
        document.addEventListener("mousedown", handleClick2);
        document.addEventListener("mousedown", handleClick3);
        document.addEventListener("mousedown", handleClick4);
        document.addEventListener("mousedown", handleClick5);
        document.addEventListener("mousedown", handleClick6);
        document.addEventListener("mousedown", handleClick7);
        document.addEventListener("mousedown", handleClick8);
        document.addEventListener("mousedown", handleClick9);
        document.addEventListener("mousedown", handleClick10);
        document.addEventListener("mousedown", handleClick11);
        document.addEventListener("mousedown", handleClick12);
        document.addEventListener("mousedown", handleClick13);
        document.addEventListener("mousedown", handleClick14);
        document.addEventListener("mousedown", handleClick15);
        document.addEventListener("mousedown", handleClick16);
        document.addEventListener("mousedown", handleClick17);
        document.addEventListener("mousedown", handleClick18);
        document.addEventListener("mousedown", handleClick19);
        document.addEventListener("mousedown", handleClick20);
    },[])
    return(
        <div className="container-fluid relative px-3">
            <div className="layout-specing">
                <div className="md:flex justify-between items-center">
                    <h5 className="text-lg font-semibold">Blank Page</h5>

                    <ul className="tracking-[0.5px] inline-flex items-center sm:mt-0 mt-3">
                        <li className="inline-block capitalize text-[14px] font-bold duration-500 dark:text-white/70 hover:text-indigo-600 dark:hover:text-white"><Link to="/index">Techwind</Link></li>
                        <li className="inline-block text-base text-slate-950 dark:text-white/70 mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight/></li>
                        <li className="inline-block capitalize text-[14px] font-bold text-indigo-600 dark:text-white" aria-current="page">Components</li>
                    </ul>
                </div>




            </div>
        </div>  
    )
}