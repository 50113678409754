import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { useAuth0 } from "@auth0/auth0-react";
import { Rating } from 'primereact/rating';

import 'primeflex/primeflex.css';  
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primeicons/primeicons.css';

const PRODUCTS = [
    {
        id: '1000',
        code: 'f230fh0g3',
        name: 'Bamboo Watch',
        description: 'Product Description',
        image: 'bamboo-watch.jpg',
        employees: 65,
        category: 'Accessories',
        quantity: 24,
        signals: 'INSTOCK',
        rating: 5
    },
    // {
    //     id: '1001',
    //     code: 'nvklal433',
    //     name: 'Shepherd Insurance',
    //     description: 'Executive Partner, COO, VP Sales, Shepherd Insurance',
    //     image: 'black-watch.jpg',
    //     employees: 401,
    //     category: 'Insurance',
    //     notes: '',
    //     segments: 'Top',
    //     signals: 'LinkedInCyber',
    //     news: "The whole world fell into disarray and when I learned ...",
    //     orders: [
    //         {
    //             id: '',
    //             customer: 'Pete Harrington',
    //             date: 'Chief Operating Officer',
    //             seniority: 'Executive',
    //             segment: '',
    //             amount: "pharrington@shepherdins.com",
    //             quantity: 1,
    //             status: 'PENDING'
    //         },
    //     ],
 
    // },
    // {
    //     id: '1001',
    //     code: 'nvklal433',
    //     name: 'SIAA',
    //     image: 'black-watch.jpg',
    //     employees: 136,
    //     category: 'Insurance',
    //     notes: '',
    //     segments: 'Top',
    //     signals: 'LinkedInCyber',
    //     news: "New Executive announces departure from company. We did ...",
    //     orders: [
    //         {
    //             id: '',
    //             customer: 'John Stanzi',
    //             productCode: 'Pete Harrington',
    //             date: 'Executive Partner, COO, VP Sales, Shepherd Insurance',
    //             seniority: 'Executive',
    //             segment: '',
    //             amount: "pharrington@shepherdins.com",
    //             quantity: 1,
    //             status: 'PENDING'
    //         },
    //     ],
 
    // },

]

export default function Outbound2() {
    const [companiesData, setCompaniesData] = useState([]);
    const [peopleData, setPeopleData] = useState({});


    const [products, setProducts] = useState(PRODUCTS.slice(1,10));
    const [expandedRows, setExpandedRows] = useState(null);
    const toast = useRef(null);

    const { user, getAccessTokenSilently } = useAuth0();
    const [iframeSrc, setIframeSrc] = useState('');

    let production = false;
    let base_url = "";
  
    if (window.location.href.includes("matryx.co")) {
      production = true;
    }
  
    if (production) {
      base_url = "https://api.matryx.co";
    } else {
      base_url = "http://127.0.0.1:5003";
    }


    useEffect(() => {
        const fetchData = async (organization) => {
            if (user) {
                const token = await getAccessTokenSilently();
                const queryParams = new URLSearchParams({ organization });
    
                try {
                    const response = await fetch(`${base_url}/getrelaysignals?${queryParams}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    
                    
                    if (response.ok) {
                        if (organization == 'companies') {

                            const data = await response.json();
                            debugger

                            return data.map((item, index) => ({
                                id: index.toString(),
                                code: item.id.toString(),
                                name: item.name,
                                description: item.short_description,
                                image: item.photo_url,
                                employees: parseInt(item.estimated_num_employees),
                                category: item.industry,
                                notes: item.notes || '',
                                segments: item.industries,
                                signals: item.at_cyber_symposium ? 'CYBER_SYMPOSIUM' : '',
                                news: item.news_and_relevance ? 'News available' : 'No news',
                                orders: [],
                                annualRevenue: item.annual_revenue_printed || '',
                                city: item.city || '',
                                country: item.country || '',
                                createdTime: item.created_time || '',
                                decisionMakers: item.decision_makers || '',
                                departmentHeadCount: item.department_head_count || '',
                                latestFundingRoundDate: item.latest_funding_round_date || '',
                                latestFundingStage: item.latest_funding_stage || '',
                                marketCap: item.market_cap || '',
                                domain: item.primary_domain || '',
                                rawAddress: item.raw_address || '',
                                recentlyAddedToCyber: item.recently_added_to_cyber || '',
                                seoDescription: item.seo_description || '',
                                state: item.state || '',
                                top100: item.top100 || '',
                                websiteUrl: item.website_url || '',
                            }));


                        } else {
                            const data = await response.json();
                            return data
                        }
                    } else {
                        console.error(`Failed to fetch relay signals data for ${organization}`);
                    }
                } catch (error) {
                    console.error(`Error fetching relay signals data for ${organization}:`, error);
                }
            }
            return [];
        };
    
        const loadAllData = async () => {
            const [companies, people] = await Promise.all([
                fetchData('companies'),
                fetchData('people')
            ]);
        
            setCompaniesData(companies);
            setPeopleData(people);
        };
    
        loadAllData();
    }, [user]); // Re-fetch when user changes


    useEffect(() => {
        // Function to fetch dashboard URL
        const fetchDashboardUrl = async () => {
            if (user) {
                // Obtain the access token silently
                const token = await getAccessTokenSilently();

                const queryParams = new URLSearchParams({
                    type: 'outbound',
                    email: user.email // Assuming you want to use the user's email as a parameter
                });
                try {
                    const response = await fetch(`${base_url}/get-user-dashboard?${queryParams}`, {
                        headers: {
                            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
                        },
                    });

                    if (response.ok) {
                        const data = await response.json();
                        setIframeSrc(data.dashboard_data); // Assuming 'dashboard_data' contains the URL
                    } else {
                        console.error('Failed to fetch dashboard URL');
                    }
                } catch (error) {
                    console.error('Error fetching dashboard URL:', error);
                }
            }
        };

        fetchDashboardUrl();
    }, [user]); // Re-fetch when user changes

    const onRowExpand = (event) => {
        // toast.current.show({ severity: 'info', summary: 'Product Expanded', detail: event.data.name, life: 3000 });
    };

    const onRowCollapse = (event) => {
        // toast.current.show({ severity: 'success', summary: 'Product Collapsed', detail: event.data.name, life: 3000 });
    };

    const expandAll = () => {
        let _expandedRows = {};

        products.forEach((p) => (_expandedRows[`${p.id}`] = true));

        setExpandedRows(_expandedRows);
    };

    const collapseAll = () => {
        setExpandedRows(null);
    };

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    };

    const amountBodyTemplate = (rowData) => {
        return rowData.amount;
        return formatCurrency(rowData.amount);
    };

    const statusOrderBodyTemplate = (rowData) => {
        return (
            <div>
                <Tag value={"rowData.status.toLowerCase()"} severity={getOrderSeverity(rowData)}></Tag>
            </div>
        );
    };

    const searchBodyTemplate = () => {
        return <Button icon="pi pi-search" />;
    };

    const imageBodyTemplate = (rowData) => {

        // TODO: Should be company logo
        const image = rowData.image || "https://placehold.co/600x400"
        return <img src={rowData.image} alt={rowData.image} width="64px" className="shadow-4 company-profile-pic" />;
    };

    const employeesBodyTemplate = (rowData) => {
        return rowData.employees;
        // return formatCurrency(rowData.employees);
    };

    const ratingBodyTemplate = (rowData) => {
        return <Rating value={rowData.rating} readOnly cancel={false} />;
    };

    const statusBodyTemplate = (rowData) => {
        // TODO:
        // The logic should be pills until a certain width of words, then single "+3" or "+4".
        // Should be two rows

        const signals = rowData.signals.split(",")

        return (
            <div>
                {signals.map((signal, index) => (
                    <Tag
                        key={index}
                        value={signal.trim()}
                        severity={getProductSeverity(rowData)}
                        style={{ marginRight: '2px', marginBottom: '2px' }}
                    />
                ))}
            </div>
        );
    };

    const getProductSeverity = (product) => {
        switch (product.signals) {
            case 'INSTOCK':
                return 'success';

            case 'LOWSTOCK':
                return 'warning';

            case 'OUTOFSTOCK':
                return 'danger';

            default:
                return null;
        }
    };

    const getOrderSeverity = (order) => {
        switch (order.status) {
            case 'DELIVERED':
                return 'success';

            case 'CANCELLED':
                return 'danger';

            case 'PENDING':
                return 'warning';

            case 'RETURNED':
                return 'info';

            default:
                return null;
        }
    };

    const allowExpansion = (rowData) => {
        const companyDomain = rowData.domain;
        debugger
        return peopleData[companyDomain] && peopleData[companyDomain].length > 0;
    };

    const rowExpansionTemplate = (data) => {
        const companyDomain = data.domain; // Assuming the company object has a 'domain' property
        const peopleList = peopleData[companyDomain] || []; // Get the list of people for the company's domain
    
        debugger
        return (
            <div className="p-3">
                <h5>Employees at {data.name}</h5>
                <DataTable 
                    scrollable 
                    scrollHeight="400px" 
                    value={peopleList}
                    resizableColumns 
                    showGridlines

                    >
                        <Column field="id" header="Picture" sortable></Column>
                        <Column header="X" body={imageBodyTemplate} style={{ maxWidth: '50px' }} />
                        <Column field="customer" header="Name" sortable></Column>
                        <Column field="productCode" header="Title" sortable></Column>
                        <Column field="seniority" header="Seniority" sortable></Column>
                        <Column field="status" header="Status" body={statusOrderBodyTemplate} sortable></Column>
                        <Column field="segment" header="Segments" body={statusOrderBodyTemplate} sortable></Column>
                        <Column field="amount" header="Email" body={amountBodyTemplate} sortable></Column>
                        {/* <Column headerStyle={{ width: '4rem' }} body={searchBodyTemplate}></Column> */}
                </DataTable>
            </div>
        );
    };

    const header = (
        <div className="flex flex-wrap justify-content-end gap-2">
            <Button icon="pi pi-plus" label="Expand All" onClick={expandAll} text />
            <Button icon="pi pi-minus" label="Collapse All" onClick={collapseAll} text />
        </div>
    );


    return (
        <div className="body-container" style={{marginTop: '120px'}}>
            <h1 className="text-lg font-semibold large-heading" style={{marginLeft: '30px'}}>
                Accounts
            </h1>

            <div className="card2">
                <Toast ref={toast} />
                <DataTable 
                        value={companiesData} 
                        paginator
                        rows={10}
                        size={'small'}
                        resizableColumns 
                        showGridlines
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        expandedRows={expandedRows} 
                        onRowToggle={(e) => setExpandedRows(e.data)}
                        onRowExpand={onRowExpand} 
                        onRowCollapse={onRowCollapse} 
                        rowExpansionTemplate={rowExpansionTemplate}
                        dataKey="id" 
                        header={header} 
                        headerStyle={{ 
                            // backgroundColor: 'rgb(255, 255, 255) !important' ,
                            // fontSize: "14px !important",
                        }}
                        tableStyle={{ 
                            minWidth: '60rem', 
                            width: '80%', 
                            marginLeft: '20px', 
                            border: '1px solid lightgrey',
                            borderRadius: '5px', 
                        }}>
                    <Column expander={allowExpansion} style={{ width: '10px !important' }} />
                    <Column header="X" body={imageBodyTemplate} style={{ maxWidth: '50px' }} />
                    <Column field="name" header="Name" sortable />
                    <Column field="employees" header="Employees" sortable body={employeesBodyTemplate}  style={{ maxWidth: '60px' }}/>
                    <Column field="signals" header="Signals" sortable body={statusBodyTemplate}  style={{ minWidth: '200px' }}/>
                    <Column field="category" header="Category" sortable />
                    <Column field="notes" header="Notes" sortable />
                    <Column field="segments" header="Segments" sortable />
                    <Column field="news" header="News" sortable />
                </DataTable>
            </div>
        </div>
    );
}