import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '../src/assets/css/tailwind.css'
import "../src/assets/css/materialdesignicons.min.css"
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';


// Create a wrapper component for Auth0Provider to use useNavigate
const Auth0ProviderWithRedirect = ({ children }) => {

  return (
    <Auth0Provider
      domain="matryx.us.auth0.com"
      clientId="XV1rrTolId1DwezKI3xBXH1ahdxpdJf4"
      redirectUri={window.location.origin}
    >
      {children}
    </Auth0Provider>
  );
};


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <Auth0ProviderWithRedirect>
      <App />
    </Auth0ProviderWithRedirect>
  </Router>,
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
