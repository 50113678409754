import React,{useState, useEffect} from "react";

import { Link, useLocation } from "react-router-dom";
import * as Icon from 'react-feather';
import {TbPhotoPlus, AiOutlineAndroid, RiBitCoinLine, MdOutlineCalendarMonth, AiOutlinePieChart, MdKeyboardArrowRight,AiOutlineExclamationCircle,AiOutlineCheckCircle, AiOutlineInfoCircle, MdKeyboardArrowDown, AiOutlineSetting, VscMention, MdKeyboardArrowLeft, BsHeartbreak,LiaClipboardCheckSolid, FiLock, BsExclamationTriangle,BsExclamationOctagon} from '../assets/icons/vander'

import {accordion} from '../data/data'
import { CompactTable } from '@table-library/react-table-library/compact';

import { useTheme } from '@table-library/react-table-library/theme';
import { getTheme } from '@table-library/react-table-library/baseline';

// import { nodes } from '../data';

import HighIntentTable from "./HighIntentTable";
import { useAuth0 } from "@auth0/auth0-react";


// const headers = ['firstName', 'lastName', 'domain', 'email', 'linkedin_url', 'emailBody']


// TODO:
// Hardcode Sheets URL
// Set data = sheets
// Dynamically create leads var from sheets
// Every 2 second, fetch the latest sheet
// Dynamically set HighIntentTable.js based on sheet

export default function Outbound(){
    const [leadData, setLeadData] = useState([]);
    const [spreadsheetData, setSpreadsheetData] = useState([]);
    
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const sheetId = queryParams.get('sheetId');

    const { user, getAccessTokenSilently, isAuthenticated, isLoadin, logout,  } = useAuth0();
    const [iframeSrc, setIframeSrc] = useState('');

    let production = false;
    let base_url = "";
  
    if (window.location.href.includes("matryx.co")) {
      production = true;
    }
  
    if (production) {
      base_url = "https://api.matryx.co";
    } else {
      base_url = "http://127.0.0.1:5000";
    }


    useEffect(() => {
      // Function to fetch dashboard URL
      const fetchDashboardUrl = async () => {
          if (user) {
              // Obtain the access token silently
              const token = await getAccessTokenSilently();

              const queryParams = new URLSearchParams({
                  type: 'outbound',
                  email: user.email // Assuming you want to use the user's email as a parameter
              });
              try {
                const response = await fetch(`${base_url}/get-user-dashboard?${queryParams}`, {
                  headers: {
                      Authorization: `Bearer ${token}`, // Include the token in the Authorization header
                  },
                });

                if (response.ok) {
                    const data = await response.json();
                    setIframeSrc(data.dashboard_data); // Assuming 'dashboard_data' contains the URL
                } else {
                    console.error('Failed to fetch dashboard URL');
                }
              } catch (error) {
                  console.error('Error fetching dashboard URL:', error);
              }
          }
      };

      fetchDashboardUrl();
  }, [user]); // Re-fetch when user changes



    let retoolName = ""
    if (user && (user.email == 'saleh.hindi.one@gmail.com')) {
      // retoolName = "RelayOutboundScreen"
      retoolName = "MatryxOutboundScreen1"
    }

    return (
      <>
          {iframeSrc ? (
              <iframe src={iframeSrc} width="100%" height="800px" style={{marginTop: '30px'}} />
          ) : (
              <div>Loading...</div>
          )}
      </>
  );
}