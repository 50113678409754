const defaultState = [
  {
    id: "1",
    type: "input",
    data: {
      label: (
        <>
          Welcome to <strong>React Flow!</strong>
        </>
      )
    },
    position: { x: 250, y: 0 }
  },
  {
    id: "2",
    data: {
      label: (
        <>
          This is a <strong>default node</strong>
        </>
      )
    },
    position: { x: 100, y: 100 }
  },
  {
    id: "3",
    data: {
      label: (
        <>
          This one has a <strong>custom style</strong>
        </>
      )
    },
    position: { x: 400, y: 100 },
    style: {
      background: "#D6D5E6",
      color: "#333",
      border: "1px solid #222138",
      width: 180
    }
  },
  {
    id: "4",
    position: { x: 250, y: 200 },
    data: {
      label: "Another default node"
    }
  },
  {
    id: "5",
    data: {
      label: "Node id: 5"
    },
    position: { x: 250, y: 325 }
  },
  {
    id: "6",
    type: "output",
    data: {
      label: (
        <>
          An <strong>output node</strong>
        </>
      )
    },
    position: { x: 100, y: 480 }
  },
  {
    id: "7",
    type: "output",
    data: { label: "Another output node" },
    position: { x: 400, y: 450 }
  },
  { id: "e1-2", source: "1", target: "2", label: "this is an edge label" },
  { id: "e1-3", source: "1", target: "3" },
  {
    id: "e3-4",
    source: "3",
    target: "4",
    animated: true,
    label: "animated edge"
  },
  {
    id: "e4-5",
    source: "4",
    target: "5",
    arrowHeadType: "arrowclosed",
    label: "edge with arrow head"
  },
  {
    id: "e5-6",
    source: "5",
    target: "6",
    type: "smoothstep",
    label: "smooth step edge"
  },
  {
    id: "e5-7",
    source: "5",
    target: "7",
    type: "step",
    style: { stroke: "#f6ab6c" },
    label: "a step edge",
    animated: true,
    labelStyle: { fill: "#f6ab6c", fontWeight: 700 }
  }
];

// Every day, trigger a search for posts on Twitter that mention 'schlep blindness', and extract the author's name and profile description. If their description indicates they invest in early-stage companies, search for the companies they have invested in. Focus specifically on extracting compliance or backoffice companies from their investment list. Finally, write an email convincing them of how similar your company is to their portfolio companies, and display the profile name and the crafted email.
const workflow1 = [
  {
    id: "1",
    type: "input",
    data: {
      label: (
        <>
          (Trigger) Every day
        </>
      )
    },
    position: { x: 250, y: 0 }
  },
  {
    id: "2",
    data: {
      label: (
        <>
          (Action) Search for posts that mention 'schlep blindness' on Twitter and extract the author's name and profile description
        </>
      )
    },
    position: { x: 250, y: 150 }
  },
  {
    id: "4",
    position: { x: 250, y: 350 },
    data: {
      label: "(Action) Search what companies they invested in"
    }
  },
  {
    id: "5",
    data: {
      label: "(Action) Write an email saying convincing how similar we are to their portcos"
    },
    position: { x: 250, y: 525 }
  },
  {
    id: "6",
    type: "output",
    data: {
      label: (
        <>
          (Display) Profile Name
        </>
      )
    },
    position: { x: 100, y: 680 }
  },
  {
    id: "7",
    type: "output",
    data: { label: "(Display) Email" },
    position: { x: 400, y: 650 }
  },
  { id: "e1-2", source: "1", target: "2", label: "this is an edge label" },
  { id: "e2-4", source: "2", target: "4", label: "(Filter) If their description says they invest in early stage companies" },
  { id: "e1-3", source: "1", target: "3" },
  {
    id: "e3-4",
    source: "3",
    target: "4",
    animated: true,
    label: "animated edge"
  },
  {
    id: "e4-5",
    source: "4",
    target: "5",
    arrowHeadType: "arrowclosed",
    label: "(Filter) Extract the compliance or backoffice companies from the list of companies"
  },
  {
    id: "e5-6",
    source: "5",
    target: "6",
    type: "smoothstep",
    label: "smooth step edge"
  },
  {
    id: "e5-7",
    source: "5",
    target: "7",
    type: "step",
    style: { stroke: "#f6ab6c" },
    label: "a step edge",
    animated: true,
    labelStyle: { fill: "#f6ab6c", fontWeight: 700 }
  }
];

// Everyday, check if anyone on reddit says something about "explaining a meme" or is 
// asking someone to explain a political joke. If so, search for the image of the joke they 
// are asking about. Then write a message saying what the joke means.
const promoteExplainTheJokeWorkflow = [
  {
    id: "1",
    type: "input",
    data: {
      label: (
        <>
          (Trigger) Once
        </>
      )
    },
    position: { x: 250, y: 0 }
  },
  {
    id: "2",
    data: {
      label: (
        <>
          (Action) List of accounts
        </>
      )
    },
    position: { x: 250, y: 150 }
  },
  // {
  //   id: "3",
  //   data: {
  //     label: (
  //       <>
  //         This one has a <strong>custom style</strong>
  //       </>
  //     )
  //   },
  //   position: { x: 400, y: 100 },
  //   style: {
  //     background: "#D6D5E6",
  //     color: "#333",
  //     border: "1px solid #222138",
  //     width: 180
  //   }
  // },
  {
    id: "4",
    position: { x: 250, y: 350 },
    data: {
      label: "(Action) Search all social media sites for posts that mention company"
    }
  },
  {
    id: "5",
    data: {
      label: "(Action) Write a response in the following style labeling why they feel a certain way. Label the negative emotion. Add more Chriss Voss stuff"
    },
    position: { x: 250, y: 525 }
  },
  {
    id: "9",
    data: {
      label: "(Action) Create a structured JSON of original_message, message_summary, sentiment_score, date(optional)"
    },
    position: { x: 550, y: 525 }
  },
  {
    id: "6",
    type: "output",
    data: {
      label: (
        <>
          (Display) Profile Name
        </>
      )
    },
    position: { x: 100, y: 650 }
  },
  {
    id: "7",
    type: "output",
    data: { label: "(Display) Email" },
    position: { x: 400, y: 650 }
  },
  { id: "e1-2", source: "1", target: "2" },
  { id: "e2-4", source: "2", target: "4"},
  { id: "e1-3", source: "1", target: "3" },
  { id: "e4-9", source: "4", target: "9" },
  {
    id: "e3-4",
    source: "3",
    target: "4",
    // animated: true,
    // label: "animated edge"
  },
  {
    id: "e4-5",
    source: "4",
    target: "5",
    // arrowHeadType: "arrowclosed",
    label: "(Filter) Extract the compliance or backoffice companies from the list of companies"
  },
  {
    id: "e5-6",
    source: "5",
    target: "6",
    // type: "smoothstep",
    // label: "smooth step edge"
  },
  {
    id: "e5-7",
    source: "5",
    target: "7",
    // type: "step",
    // style: { stroke: "#f6ab6c" },
    // label: "a step edge",
    // animated: true,
    // labelStyle: { fill: "#f6ab6c", fontWeight: 700 }
  }
];

// AI Generated
// Trigger the Pitch NYC AI Journalists Workflow and search for journalists in NYC using Apollo. For each journalist found, search Google for AI news related to them. Determine if the journalist writes about AI. If they do, process each Google search result. Scrape the content of the pages found, and generate observations and compliments based on this content. Draft email bodies using these observations and compliments. Finally, upload the data to Google Sheets, marking the end of the workflow. 
const pitchNYCAIJournalistsWorkflow = [
  { id: "1", type: "input", data: { label: "(Trigger) Pitch NYC AI Journalists Workflow" }, position: { x: 250, y: 0 } },
  { id: "2", data: { label: "(Action) Search for journalists in NYC via Apollo" }, position: { x: 250, y: 150 } },
  { id: "3", data: { label: "(Filter) For each Journalist" }, position: { x: 250, y: 300 } },
  { id: "4", data: { label: "(Action) Search Google for AI news related to Journalist" }, position: { x: 250, y: 450 } },
  { id: "5", data: { label: "(Action) Determine if Journalist writes about AI" }, position: { x: 250, y: 600 } },
  { id: "6", data: { label: "(Decision) If Journalist writes about AI" }, position: { x: 250, y: 750 } },
  { id: "7", data: { label: "(Action) Process each Google Search Result" }, position: { x: 250, y: 900 } },
  { id: "8", data: { label: "(Action) Scrape Page Content" }, position: { x: 500, y: 900 } },
  { id: "9", data: { label: "(Action) Generate Observations and Compliments" }, position: { x: 750, y: 900 } },
  { id: "10", data: { label: "(Action) Draft Email Bodies" }, position: { x: 250, y: 1050 } },
  { id: "11", data: { label: "(Action) Upload Data to Google Sheets" }, position: { x: 250, y: 1200 } },
  { id: "12", type: "output", data: { label: "(Output) End of Workflow" }, position: { x: 250, y: 1350 } },
  { id: "e1-2", source: "1", target: "2" },
  { id: "e2-3", source: "2", target: "3" },
  { id: "e3-4", source: "3", target: "4" },
  { id: "e4-5", source: "4", target: "5" },
  { id: "e5-6", source: "5", target: "6" },
  { id: "e6-7", source: "6", target: "7", label: "If Yes" },
  { id: "e7-10", source: "7", target: "10" },
  { id: "e7-8", source: "7", target: "8" },
  { id: "e8-9", source: "8", target: "9" },
  { id: "e9-10", source: "9", target: "10" },
  { id: "e10-11", source: "10", target: "11" },
  { id: "e11-12", source: "11", target: "12" }
];
  
// export default pitchNYCAIJournalistsWorkflow;
  
  
const testWorkflow = [
  { "id": "1", "type": "input", "data": { "label": "(Trigger) Search for podcast episodes with early-stage founders" }, "position": { "x": 250, "y": 25 } },
  { "id": "2", "data": { "label": "(Action) Summarize podcast and pull out interesting info per episode" }, "position": { "x": 250, "y": 150 } },
  { "id": "3", "data": { "label": "(Action) Extract name and company of podcast host" }, "position": { "x": 250, "y": 275 } },
  { "id": "4", "data": { "label": "(Action) Get podcast host's email" }, "position": { "x": 250, "y": 400 } },
  { "id": "5", "data": { "label": "(Action) Compose email to host about episode and content interest" }, "position": { "x": 250, "y": 525 } },
  { "id": "6", "type": "output", "data": { "label": "(End) End of Workflow" }, "position": { "x": 250, "y": 650 } },
  { "id": "e1-2", "source": "1", "target": "2" },
  { "id": "e2-3", "source": "2", "target": "3" },
  { "id": "e3-4", "source": "3", "target": "4" },
  { "id": "e4-5", "source": "4", "target": "5" },
  { "id": "e5-6", "source": "5", "target": "6" }
]


const DeveloperDocsWorkflow = [
  {
    "id": "1",
    "type": "input",
    "data": {
      "label": "(Trigger) Search Google for companies using Mintlify"
    },
    "position": { "x": 250, "y": 0 }
  },
  {
    "id": "2",
    "data": {
      "label": "(Action) Extract company names"
    },
    "position": { "x": 250, "y": 150 }
  },
  {
    "id": "3",
    "data": {
      "label": "(Filter) For each company name"
    },
    "position": { "x": 250, "y": 300 }
  },
  {
    "id": "4",
    "data": {
      "label": "(Action) Search Apollo for founders and salespeople"
    },
    "position": { "x": 250, "y": 450 }
  },
  {
    "id": "5",
    "data": {
      "label": "(Decision) Identify if person is a founder or in sales"
    },
    "position": { "x": 250, "y": 600 }
  },
  {
    "id": "6",
    "data": {
      "label": "(Action) Compose a compliment and reference possible acquaintance"
    },
    "position": { "x": 250, "y": 750 }
  },
  {
    "id": "7",
    "data": {
      "label": "(Action) Draft an email based on these facts"
    },
    "position": { "x": 250, "y": 900 }
  },
  {
    "id": "8",
    "type": "output",
    "data": {
      "label": "(Output) Emails ready to be sent"
    },
    "position": { "x": 250, "y": 1050 }
  },
  {
    "id": "e1-2",
    "source": "1",
    "target": "2"
  },
  {
    "id": "e2-3",
    "source": "2",
    "target": "3"
  },
  {
    "id": "e3-4",
    "source": "3",
    "target": "4"
  },
  {
    "id": "e4-5",
    "source": "4",
    "target": "5"
  },
  {
    "id": "e5-6",
    "source": "5",
    "target": "6"
  },
  {
    "id": "e6-7",
    "source": "6",
    "target": "7"
  },
  {
    "id": "e7-8",
    "source": "7",
    "target": "8"
  }
]


const mintify = [
  {
    "id": "1",
    "type": "input",
    "data": {
      "label": "(Trigger) pitch_mintlify_users_workflow function"
    },
    "position": { "x": 100, "y": 50 }
  },
  {
    "id": "2",
    "data": {
      "label": "(Action) Sleep for 5 seconds"
    },
    "position": { "x": 100, "y": 150 }
  },
  {
    "id": "3",
    "data": {
      "label": "(Action) Initialize header for Google Sheets"
    },
    "position": { "x": 100, "y": 250 }
  },
  {
    "id": "4",
    "data": {
      "label": "(Action) Upload header to Google Sheets"
    },
    "position": { "x": 100, "y": 350 }
  },
  {
    "id": "5",
    "data": {
      "label": "(Action) Scrape page for each result"
    },
    "position": { "x": 100, "y": 450 }
  },
  {
    "id": "6",
    "data": {
      "label": "(Decision) Check if page contains 'clearbit'"
    },
    "position": { "x": 100, "y": 550 }
  },
  {
    "id": "7",
    "data": {
      "label": "(Action) Search via Apollo if 'clearbit' not found"
    },
    "position": { "x": 100, "y": 650 }
  },
  {
    "id": "8",
    "data": {
      "label": "(Decision) Check if Apollo results found"
    },
    "position": { "x": 100, "y": 750 }
  },
  {
    "id": "9",
    "data": {
      "label": "(Action) Extract key information from Apollo results"
    },
    "position": { "x": 100, "y": 850 }
  },
  {
    "id": "10",
    "data": {
      "label": "(Action) Enrich data via Apollo if name and domain available"
    },
    "position": { "x": 100, "y": 950 }
  },
  {
    "id": "11",
    "data": {
      "label": "(Action) Upload enriched data to Google Sheets"
    },
    "position": { "x": 100, "y": 1050 }
  },
  {
    "id": "12",
    "type": "output",
    "data": {
      "label": "(Output) End of Workflow"
    },
    "position": { "x": 100, "y": 1150 }
  },
  {
    "id": "e1-2",
    "source": "1",
    "target": "2"
  },
  {
    "id": "e2-3",
    "source": "2",
    "target": "3"
  },
  {
    "id": "e3-4",
    "source": "3",
    "target": "4"
  },
  {
    "id": "e4-5",
    "source": "4",
    "target": "5"
  },
  {
    "id": "e5-6",
    "source": "5",
    "target": "6"
  },
  {
    "id": "e6-7",
    "source": "6",
    "target": "7",
    "label": "If 'clearbit' not found"
  },
  {
    "id": "e7-8",
    "source": "7",
    "target": "8"
  },
  {
    "id": "e8-9",
    "source": "8",
    "target": "9",
    "label": "If Apollo results found"
    },
    {
    "id": "e9-10",
    "source": "9",
    "target": "10"
    },
    {
    "id": "e10-11",
    "source": "10",
    "target": "11"
    },
    {
    "id": "e11-12",
    "source": "11",
    "target": "12"
    }
  ]
    
// const generated = [{'id': '1', 'type': 'input', 'data': {'label': '(Action) Search for podcast episodes featuring early stage founders'}, 'position': {'x': 250, 'y': 25}}, {'id': '2', 'data': {'label': '(Filter) For each episode'}, 'position': {'x': 250, 'y': 150}}, {'id': '3', 'data': {'label': '(Action) Summarize the podcast and pull out interesting information'}, 'position': {'x': 250, 'y': 275}}, {'id': '4', 'data': {'label': '(Action) Extract the name and company of the podcast host'}, 'position': {'x': 250, 'y': 400}}, {'id': '5', 'data': {'label': "(Action) Get the host's email"}, 'position': {'x': 250, 'y': 525}}, {'id': '6', 'data': {'label': '(Action) Compose a short email to the host'}, 'position': {'x': 250, 'y': 650}}, {'id': '7', 'type': 'output', 'data': {'label': '(Output) Send email'}, 'position': {'x': 250, 'y': 775}}]
    
const empty = []


export default empty