import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Handle } from "react-flow-renderer";
import googleLogo from '../assets/images/google-logo.svg'
import apolloLogo from '../assets/images/apollo-logo.png'
import slackLogo from '../assets/images/slack-logo.png'
import clearbitLogo from '../assets/images/clearbit-logo.png'
import linkLogo from '../assets/images/link-logo.png'
import googleSheetsLogo from '../assets/images/google-sheets-logo.png'
import fileUploadIcon from '../assets/images/file-upload-icon.png'
import inferenceLogo from '../assets/images/sparkle-logo.png'
import eyeLogo from '../assets/images/eye-logo.png'
import linkedinLogo from '../assets/images/linkedin-logo.svg'
import personLogo from '../assets/images/person-logo.png'
import forEachIcon from '../assets/images/foreach-icon.png'
import matryxLogo from '../assets/images/logo-icon-64.png'
import ifStatement from '../assets/images/if-statement.png'
import clock from '../assets/images/clock.png'
import similarweb from '../assets/images/similarweb.png'


// TODO: Fix display node
// TODO: Change styling so that if customNode, then remove inner box, move text around,
// TODO: Depending on nodeType, make handle input, middle, or outputNode
// TODO: Set color, icon, title, 
// TODO: Make 3 Integrations classes that inherit from CustomNode
const CustomNode = ({data}) => {
    const fromCanvas = data.fromCanvas == 1
    // console.log("In CustomNode")
    // console.log("data.inputProps")
    // console.log(data.inputProps)

    // These are node props and should be the only thing important to CustomNode
    const [label, setLabel] = useState(data.label || "Default label");
    const [nodeType, setNodeType] = useState(data.type);
    const [functionName, setFunctionName] = useState(data.functionName);
    const [color, setColor] = useState(data.color || "white")
    const [icon, setIcon] = useState(data.icon || "");
    const [inputProps, setInputProps] = useState(data.inputProps || {})
    const [outputProps, setOutputProps] = useState(data.outputProps || {})

    // TODO: Why do I need this???
    // inputProps = inputProps || {}

    // if (nodeType == "input") {
    //     setColor('red')
    // }
    // if (nodeType == "default") {
    //     setColor('blue')
    // }
    // if (nodeType == "output") {
    //     setColor('yellow')
    // }

    useEffect(() => {
        switch(nodeType) {
            case "input":
                setColor("green");
                break;
            case "default":
                setColor("red");
                break;
            case "output":
                setColor("blue");
                break;
            // case "output":
            //     setColor("red");
            //     break;
            case "inference":
                setColor("red");
                break;
            default:
                setColor("white");
        }
    }, [nodeType]);

    useEffect(() => {
        if (data.label) {
            setLabel(data.label);
        }
    }, [data.label]);

    useEffect(() => {
        if (data.inputProps) {
            setInputProps(data.inputProps);
        }
    }, [data.inputProps]);

    const onDragStart = (event, nodeData) => {
        // console.log("const onDragStart: ")
        // console.log("nodeData: ", nodeData)
        
        nodeData['color'] = color
        nodeData['icon'] = icon
        nodeData['inputProps'] = inputProps
        nodeData['outputProps'] = outputProps
        nodeData['functionName'] = functionName
        event.dataTransfer.setData("application/reactflow", JSON.stringify(nodeData));
        event.dataTransfer.effectAllowed = "move";
        // console.log("nodeData: ", nodeData)
    };    

    const createKeyValueFields = (inputProps) => {
        if (!inputProps) {return (<div />)}
        // Find the element with id='description'
        const descriptionProp = inputProps.find(prop => prop.id === 'description');
    
        return (
            <div className='output-container-wrapper'>
                {descriptionProp ? ( // Check if the descriptionProp exists
                    <div className="output-container" key={descriptionProp.id}>
                        <div className="value">
                            {descriptionProp.value} {/* Accessing the value of the description */}
                        </div>
                    </div>
                ) : null}
            </div>
        )
    }

    const iconMap = {
        'google-logo': googleLogo,
        'apollo-logo': apolloLogo,
        'slack-logo': slackLogo,
        'clearbit-logo': clearbitLogo,
        'link-logo': linkLogo,
        'google-sheets': googleSheetsLogo,
        'person-logo': personLogo,
        'file-upload': fileUploadIcon,
        'inference': inferenceLogo,
        'scrape': eyeLogo,
        'linkedin': linkedinLogo,
        'foreach-icon': forEachIcon,
        'matryx-logo': matryxLogo,
        'if-statement': ifStatement,
        'clock': clock,
        'similarweb-logo': similarweb,
    };

    const iconSrc = iconMap[icon] || googleLogo;
    const iconImg = <img className="small-logo-icon" src={iconSrc} />;
  
      
    const nodeClicked = (event) => {
        // alert("inside customNode")
    }

    return (
        <div
            className={"dndnode " + nodeType}
            style={{
                borderColor: color,
                position: 'relative',
                zIndex: 10000,

            }}
            onClick={nodeClicked}
            onDragStart={(event) => onDragStart(event, {
                'type': nodeType,
                'label': label
            })}
            draggable={!fromCanvas}
        >
            <div className="node-title-wrapper">
                <div className="icon2">
                    {iconImg}
                </div>
                <div className="title">
                    {label || "default label"}
                </div>
            </div>
            <div className="node-body">
                <div className="inputs">
                    {createKeyValueFields(inputProps)}
                </div>

                {/* TODO: Define return type and put that here for props */}
                {/* <div className="outputs">
                    {createKeyValueFields(props)}
                </div> */}
            </div>

            {fromCanvas && <Handle type="target" position={'top'} />}
            {fromCanvas && <Handle type="source" position={'bottom'} />}
            {/* {nodeType == "input" ? <Handle type="source" position={'bottom'} /> : <div /> }
            {nodeType == "default" ? (
                <>
                    <Handle type="target" position={'top'} />
                    <Handle type="source" position={'bottom'} />
                </>) : <div />
            }
            {nodeType == "output" ? <Handle type="target" position={'top'} /> : <div /> } */}

        </div>
    )
    
}

export { CustomNode }

// This is the node as it appears on the sidebar
const Node = (props, {data}) => {
    const [name, setName] = useState(props.name);
    const [nodeType, setNodeType] = useState(props.nodeType);
    const [icon, setIcon] = useState(props.icon);

    const onDragStart = (event, nodeData) => {
        event.dataTransfer.setData("application/reactflow", JSON.stringify(nodeData));
        event.dataTransfer.effectAllowed = "move";
    };    

    const createKeyValueFields = (props) => {
        return (
            <div>
                {Object.entries(props).map(([key, value]) => (
                    <div className="output-container" key={key}>
                        <div className="keyy">
                            {key + ":"}
                        </div>
                        <div className="value">
                            {value}
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    // console.log("In Node")
    // console.log("-- data: ", data)
    // console.log("-- props: ", props)
    
    return (
        <div
            className={"dndnode " + nodeType}
            onDragStart={(event) => onDragStart(event, {
                'type': props.nodeType,
                'name': name
            })}
            draggable
        >
            <div className="node-title-wrapper">
                {/* <div className="icon">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" class=" me-3 icon " height="20px" xmlns="http://www.w3.org/2000/svg"><path d="M888 792H200V168c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v688c0 4.4 3.6 8 8 8h752c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM305.8 637.7c3.1 3.1 8.1 3.1 11.3 0l138.3-137.6L583 628.5c3.1 3.1 8.2 3.1 11.3 0l275.4-275.3c3.1-3.1 3.1-8.2 0-11.3l-39.6-39.6a8.03 8.03 0 0 0-11.3 0l-230 229.9L461.4 404a8.03 8.03 0 0 0-11.3 0L266.3 586.7a8.03 8.03 0 0 0 0 11.3l39.5 39.7z"></path></svg>
                </div> */}
                <div className="title">
                    {props.name}
                </div>
            </div>
            <div className="node-body">
                <div className="inputs">
                    {createKeyValueFields(props)}
                </div>

                {/* TODO: Define return type and put that here for props */}
                {/* <div className="outputs">
                    {createKeyValueFields(props)}
                </div> */}
            </div>
        </div>
    )


}
Node.propTypes = {
    name: PropTypes.string.isRequired,
    nodeType: PropTypes.string.isRequired,
};
export { Node }


const InputNode = (props) => {
    const [source, setSource] = useState(props.source);

    return (
        <Node 
            name={props.name} 
            nodeType="input"
        />
    )
}
InputNode.propTypes = {
    source: PropTypes.string.isRequired,
};
export { InputNode }




// TODO: pick a better name than default
const InferenceNode = (props) => {
    const [name, setName] = useState(props.name);
    const [prompt, setPrompt] = useState(props.prompt);
    const [outputSchema, setOutputSchema] = useState(props.outputSchema);

    return (
        <Node 
            name={props.name} 
            nodeType="default"
        />
    )
}
// InferenceNode.defaultProps = {
//     name: 'Default Name',
//     prompt: 'Default Prompt',
//     outputSchema: 'Default Schema',
// };
InferenceNode.propTypes = {
    name: PropTypes.string.isRequired,
    prompt: PropTypes.string.isRequired,
    outputSchema: PropTypes.string,
};
export { InferenceNode }



    

const DisplayNode = (props) => {
    // console.log("DisplayNode name: ", props.name)
    return (
        <Node 
            name={props.name} 
            nodeType="output"
        />
    )
}
DisplayNode.propTypes = {
    // TODO:
};
export { DisplayNode }



const nodeStringTypeMap = {
    'input': InputNode.propTypes,
    'default': InferenceNode.propTypes,
    'output': DisplayNode.propTypes,
}
export { nodeStringTypeMap }


const nodeStringTypeFormTypeMap = {
    // 'input': 'textForm',
    // 'default': InferenceNode.propTypes,
    // 'output': DisplayNode.propTypes,

}
export { nodeStringTypeFormTypeMap }







const SpreadsheetInputNode = (props) => {
    return (
        <InputNode 
            name={props.name} 
            nodeType="input"
            source={props.spreadsheetUrl}
        />
    )
}
SpreadsheetInputNode.propTypes = {
    name: PropTypes.string.isRequired,
    spreadsheetUrl: PropTypes.string.isRequired,
};
export { SpreadsheetInputNode }



const ScrapeInputNode = (props) => {
    return (
        <InputNode 
            name={props.name} 
            nodeType="input"
            source={props.url}
        />
    )
}
ScrapeInputNode.propTypes = {
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
};
export { ScrapeInputNode }





const LinkedInProfileInputNode = (props) => {
    return (
        <InputNode 
            name={props.name} 
            nodeType="input"
            source={props.profileUrl}
        />
    )
}
LinkedInProfileInputNode.propTypes = {
    name: PropTypes.string.isRequired,
    profileUrl: PropTypes.string.isRequired,
};
export { LinkedInProfileInputNode }


const SearchInputNode = (props) => {
    return (
        <InputNode 
            name={props.name} 
            nodeType="input"
            source={props.engine + ', ' + props.timePeriod + ', ' + props.query + ', ' + props.maxResults}
        />
    )
}
SearchInputNode.propTypes = {
    name: PropTypes.string.isRequired,
    engine: PropTypes.string.isRequired,
    timePeriod: PropTypes.string.isRequired,
    query: PropTypes.string.isRequired,
    maxResults: PropTypes.string.isRequired,
};
export { SearchInputNode }


const InferenceAINode = (props) => {
    return (
        <InferenceNode 
            name={props.name}
            prompt={props.prompt}
            outputSchema={props.outputSchema}
        />
    )
}
InferenceAINode.propTypes = {
    name: PropTypes.string.isRequired,
    prompt: PropTypes.string.isRequired,
    outputSchema: PropTypes.string.isRequired,
    retries: PropTypes.number.isRequired,
    errorMessage: PropTypes.string,
};
export { InferenceAINode }


const SpreadsheetDisplayNode = (props) => {
    return (
        <DisplayNode 
            name={props.name} 
            nodeType="output"
        />
    )
}
SpreadsheetDisplayNode.propTypes = {
    name: PropTypes.string.isRequired,
    columns: PropTypes.string.isRequired,
    apolloSequence: PropTypes.string,
    salesloftCadence: PropTypes.string,
};
export { SpreadsheetDisplayNode }


const HTMLOutputNode = (props) => {
    return (
        <DisplayNode 
            name={props.name} 
            nodeType="output"
        />
    )
}
HTMLOutputNode.propTypes = {
    name: PropTypes.string.isRequired,
    htmlCodeSchema: PropTypes.string.isRequired,
};
export { HTMLOutputNode }



const SpreadsheetOutputNode = (props) => {
    // console.log("SpreadsheetOutputNode: ", props.name)
    return (
        <DisplayNode 
            name={props.name} 
            nodeType="output"
        />
    )
}
SpreadsheetOutputNode.propTypes = {
    name: PropTypes.string.isRequired,
    columns: PropTypes.string.isRequired,
    apolloSequence: PropTypes.string,
    salesloftCadence: PropTypes.string,
};
export { SpreadsheetOutputNode }

const nodeTypesDefinitons = {
    'input': CustomNode,
    'default': CustomNode,
    // 'display': CustomNode,
    'output': CustomNode,
}
export { nodeTypesDefinitons }