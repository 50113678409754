import * as Icon from 'react-feather'

import Blog1 from '../assets/images/blog/01.jpg'
import Blog2 from '../assets/images/blog/02.jpg'
import Blog3 from '../assets/images/blog/03.jpg'
import Blog4 from '../assets/images/blog/04.jpg'
import Blog5 from '../assets/images/blog/05.jpg'
import Blog6 from '../assets/images/blog/06.jpg'
import Blog7 from '../assets/images/blog/07.jpg'
import Blog8 from '../assets/images/blog/08.jpg'

import product1 from '../assets/images/shop/items/s1.jpg'
import product2 from '../assets/images/shop/items/s2.jpg'
import product3 from '../assets/images/shop/items/s3.jpg'
import product4 from '../assets/images/shop/items/s4.jpg'
import product5 from '../assets/images/shop/items/s5.jpg'
import product6 from '../assets/images/shop/items/s6.jpg'
import product7 from '../assets/images/shop/items/s7.jpg'
import product8 from '../assets/images/shop/items/s8.jpg'
import product9 from '../assets/images/shop/items/s13.jpg'
import product10 from '../assets/images/shop/items/s14.jpg'

import portfolio1 from '../assets/images/workflow_4.png'
import portfolio2 from '../assets/images/workflow_5.png'
import portfolio3 from '../assets/images/workflow_3.png'
import portfolio6 from '../assets/images/workflow12.png'
import portfolio4 from '../assets/images/portfolio/4.jpg'
import portfolio5 from '../assets/images/portfolio/6.jpg'
import portfolio7 from '../assets/images/workflow_7.png'
import portfolio80 from '../assets/images/workflow_8.png'
import portfolio100 from '../assets/images/workflow10.png'
import portfolio8 from '../assets/images/portfolio/8.jpg'
import portfolio9 from '../assets/images/portfolio/9.jpg'
import portfolio90 from '../assets/images/workflow_9.png'
import portfolio10 from '../assets/images/portfolio/20.jpg'
import portfolio11 from '../assets/images/portfolio/21.jpg'
import portfolio12 from '../assets/images/portfolio/22.jpg'
import portfolio13 from '../assets/images/portfolio/23.jpg'
import portfolio14 from '../assets/images/portfolio/7.jpg'
import portfolio15 from '../assets/images/portfolio/4.jpg'
import portfolio101 from '../assets/images/workflow11.png'

import client1 from '../assets/images/client/01.jpg';
import client2 from '../assets/images/client/02.jpg';
import client3 from '../assets/images/client/03.jpg';
import client4 from '../assets/images/client/04.jpg';
import client5 from '../assets/images/client/05.jpg';
import client6 from '../assets/images/client/06.jpg';
import client7 from '../assets/images/client/07.jpg';
import client8 from '../assets/images/client/08.jpg';
import client9 from '../assets/images/client/09.jpg';
import client10 from '../assets/images/client/10.jpg';
import client11 from '../assets/images/client/11.jpg';
import client12 from '../assets/images/client/12.jpg';

import single1 from '../assets/images/shop/single/single-2.jpg'
import single2 from '../assets/images/shop/single/single-3.jpg'
import single3 from '../assets/images/shop/single/single-4.jpg'
import single4 from '../assets/images/shop/single/single-5.jpg'
import single5 from '../assets/images/shop/single/single-6.jpg'

import visaImg from '../assets/images/payments/visa.png'
import americanimg from '../assets/images/payments/american-ex.png'
import discover from '../assets/images/payments/discover.png'
import masterCard from '../assets/images/payments/master-card.png'

import circleLogo from '../assets/images/client/circle-logo.png'
import facebookLogo from '../assets/images/client/facebook-logo-2019.png'
import spotifyLogo from '../assets/images/client/spotify.png'

import coin1 from '../assets/images/coin/bitcoin.png'
import coin2 from '../assets/images/coin/litecoin.png'
import coin3 from '../assets/images/coin/auroracoin.png'
import coin4 from '../assets/images/coin/coinye.png'
import coin5 from '../assets/images/coin/ethereum.png'
import coin6 from '../assets/images/coin/potcoin.png'
import coin7 from '../assets/images/coin/zcash.png'
import coin8 from '../assets/images/coin/primecoin.png'
import coin9 from '../assets/images/coin/blocknet.png'
import coin10 from '../assets/images/coin/kucoin.png'

export  const blogData = [
    {
        id:1,
        image:Blog1,
        title:'Design your apps in your own way',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        id:2,
        image:Blog2,
        title:'Design your apps in your own way',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        id:3,
        image:Blog3,
        title:'Design your apps in your own way',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        id:4,
        image:Blog4,
        title:'Design your apps in your own way',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        id:5,
        image:Blog5,
        title:'Design your apps in your own way',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        id:6,
        image:Blog6,
        title:'Design your apps in your own way',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        id:7,
        image:Blog7,
        title:'Design your apps in your own way',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        id:8,
        image:Blog8,
        title:'Design your apps in your own way',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
]
export const productData = [
    {
        id:1,
        image: product1,
        name:'Branded T-Shirt',
    },
    {
        id:2,
        image: product2,
        name:'Shopping Bag',
    },
    {
        id:3,
        image: product3,
        name:'Elegent Watch',
    },
    {
        id:4,
        image: product4,
        name:'Casual Shoes',
    },
    {
        id:5,
        image: product5,
        name:'Earphones',
    },
    {
        id:6,
        image: product6,
        name:'Elegent Mug',
    },
    {
        id:7,
        image: product7,
        name:'Sony Headphones',
    },
    {
        id:8,
        image: product8,
        name:'Wooden Stools',
    },
    {
        id:9,
        image: product9,
        name:'Wooden Stools',
    },
    {
        id:10,
        image: product10,
        name:'Wooden Stools',
    },
]

export const productCartData = [
    {
        image:product1,
        name:'T-shirt (M)',
        amount:'280',
        qtn:'3'
    },
    {
        image:product2,
        name:'Bag',
        amount:'160',
        qtn:'1'
    },
    {
        image:product3,
        name:'Watch (Men)',
        amount:'500',
        qtn:'1'
    }
]
export const productReview = [
    {
        image:client1,
        name:'Calvin Carlo',
        time:'13th March 2023 at 01:00 pm',
        review: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour '
    },
    {
        image:client2,
        name:'Tiffany Betancourt',
        time:'6th May 2023 at 10:05 am',
        review: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour '
    },
    {
        image:client3,
        name:'Mari Harrington',
        time:'19th June 2023 at 09:15 am',
        review: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour '
    },
    {
        image:client4,
        name:'Donna Schultz',
        time:'20th june 2024 at 01:00 pm',
        review: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour '
    },
]
export const productService =[
    single1,single2,single3,single4,single5
]
export const galleryData = [
    {
        id:0,
        image:portfolio80,
        title:'Enrich Conference Attendee List',
        subTitle:'Based on FirstName, LastName, Organization',
        category:'sales',
        workflowId: "28728",
    },
    {
        id:1,
        image:portfolio90,
        title:'Extract Activities from LinkedIn Url',
        subTitle:'Get social activity from LinkedIn Profile URL',
        category:'marketing',
        workflowId: "440617",
    },
    {
        id:2,
        image:portfolio6,
        title:'Extract Events from Prospects',
        subTitle:'Look for upcoming events prospects are attending',
        category:'sales',
        workflowId: "492685",
    },
    // {
    //     id:3,
    //     image:portfolio101,
    //     title:'Mentions Competitors on LinkedIn',
    //     subTitle:'Given CSV extract which people mention competitors',
    //     category:'competitors',
    //     workflowId: "404196",
    // },
    {
        id:4,
        image:portfolio100,
        title:'ICP Visits Website Multiple Times',
        subTitle:'Notify on Slack',
        category:'marketing',
        workflowId: "610441",
    },
]

export const images = [portfolio1,portfolio2, portfolio3, portfolio4, portfolio5, portfolio6, portfolio7, portfolio8, portfolio9, portfolio10, portfolio11, portfolio12, portfolio13, portfolio14, portfolio15]
export const orderData = [
    {
        id: 'Bloomberg',
        date: '123',
        price: "1m 25sec",
        status: 'High Intent'
    },
    {
        id: 'Apollo.io',
        date: '23',
        price: "1m 25sec",
        status: 'New Account'
    },
    {
        id: 'Your Top Account!',
        date: '23',
        price: "1m 25sec",
        status: 'New Account'
    },
    {
        id: 'Google',
        date: '23',
        price: "1m 25sec",
        status: 'News Signal'
    },
    {
        id: 'Gritt',
        date: '4',
        price: "1m 25sec",
        status: 'News Signal'
    },
    {
        id: 'Matryx Company',
        date: '3',
        price: "1m 25sec",
        status: 'None'
    },
    {
        id: 'Salesloft',
        date: '5',
        price: "1m 25sec",
        status: 'None'
    },
]
export const topProductData = [
    {
        title:'/',
        earnings:'$7500',
        Progress:'5.5%'
    },
    {
        title:'/pricing',
        earnings:'$5005',
        loss:'15.8%'
    },
    {
        title:'/documentation',
        earnings:'$2495',
        Progress:'1.3%'
    },
]
export const profitData = [
    {
        icon: Icon.Users,
        title: 'Visitors',
        amount : '5234',
    },
    {
        icon: Icon.Home ,
        title: 'Accounts',
        amount : '189',
    },
    {
        icon: Icon.UserCheck,
        title: 'ICP',
        amount : '160',
    },
    {
        icon: Icon.Home,
        title: 'Opportunities',
        amount : '20',
    },
    {
        icon: Icon.DollarSign,
        title: 'Revenue',
        amount : '7200',
    },
]
export const userData = [
    {
        image: client1,
        name: 'Christopher',
        lastSeen: '10 Min',
        active: true,
        msg:'Hello'
    },
    {
        image: client2,
        name: 'Dr. Cristino',
        lastSeen: '20 Min',
        active: false,
        msg:'Hi, How are you?'
    },
    {
        image: client3,
        name: 'Faye',
        lastSeen: '30 Min',
        active: false,
        msg:'Heyy'
    },
    {
        image: client4,
        name: 'Ronald',
        lastSeen: '2 Hours',
        active: false,
        msg:'Hey, How are you sir?'
    },
    {
        image: client5,
        name: 'Melissa',
        lastSeen: '3 Hours',
        active: true,
        msg:'Good Afternoon'
    },
    {
        image: client6,
        name: 'Elsie',
        lastSeen: '10 Hours',
        active: true,
        msg:'Good Morning sir, how can i help you?'
    },
    {
        image: client7,
        name: 'Jerry',
        lastSeen: '16 Hours',
        active: true,
        msg:'Please give me appointment'
    },
    {
        image: client8,
        name: 'Louis',
        lastSeen: '1 Days',
        active: false,
        msg:'Hii'
    },
    {
        image: client9,
        name: 'Randall',
        lastSeen: '2 Days',
        active: true,
        msg:'Hello Sir'
    },
    {
        image: client10,
        name: 'Mary',
        lastSeen: '3 Days',
        active: false,
        msg:'How are you sir?'
    },
    {
        image: client11,
        name: 'Lester',
        lastSeen: '4 Days',
        active: false,
        msg:'Hello please give me answer.'
    },
    {
        image: client12,
        name: 'Christopher',
        lastSeen: '10 Days',
        active: true,
        msg:'Hello'
    },
]

export const inboxData = [
    {
        star : false,
        name : 'Calvin Carlo',
        subject : 'Techwind Customization',
        time : '03:05PM'
    },
    {
        star : false,
        name : 'Madeleine Jackson',
        subject : 'User-friendly value-added application 😊',
        time : '12:00PM'
    },
    {
        star : true,
        name : 'Sherrie Miller',
        subject : 'Focused impactful open system 📷 😃',
        time : '8hours ago'
    },
    {
        star : false,
        name : 'John Belgrave',
        subject : 'Profound systemic alliance 🎉 🎊',
        time : '20hours ago'
    },
    {
        star : false,
        name : 'Jimmy Bojorquez',
        subject : 'Organized value-added model',
        time : '13th March 2023'
    },
    {
        star : false,
        name : 'Louise Stewart',
        subject : 'Waterfall Model Update',
        time : '6th May 2023'
    },
    {
        star : false,
        name : 'Kelly Hair',
        subject : 'Company Report',
        time : '19th June 2023'
    },
    {
        star : true,
        name : 'Ester Casella',
        subject : 'Theme Update',
        time : '20th June 2023'
    },
    {
        star : true,
        name : 'Richard Benavides',
        subject : 'Your product has been updated!',
        time : '31st August 2023'
    },
    {
        star : false,
        name : 'Calvin Hudson',
        subject : 'ThemeForest Sale',
        time : '1st September 2023'
    },
]
export const blogComments = [
    {
        image:client1,
        name:'Calvin Carlo',
        time:'13th March 2023 at 01:00 pm',
        comment: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour '
    },
    {
        image:client2,
        name:'Tiffany Betancourt',
        time:'6th May 2023 at 10:05 am',
        comment: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour '
    },
    {
        image:client3,
        name:'Mari Harrington',
        time:'19th June 2023 at 09:15 am',
        comment: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour '
    },
    {
        image:client4,
        name:'Donna Schultz',
        time:'20th june 2024 at 01:00 pm',
        comment: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour '
    },
]
export const recentPost = [
    {
        image:Blog1,    
        title:'Consultant Business',
        time:'13th Sep 2023'
    },
    {
        image:Blog2,    
        title:'Grow Your Business',
        time:'29th Nov 2023'
    },
    {
        image:Blog3,    
        title:'Look On The Glorious Balance',
        time:'29th Dec 2023'
    }
]
export  const invoiceData = [
    {
        no:'#tw001',
        image:client1,
        name:'Howard Tanner',
        phoneNo:'(+12)85-4521-7568',
        amount:'$253',
        date:'29th Nov 2023',
        status:'unpaid'
    },
    {
        no:'#tw002',
        image:client2,
        name:'Howard Tanner',
        phoneNo:'(+12)85-4521-7568',
        amount:'$482',
        date:'31st Dec 2023',
        status:'Paid'
    },
    {
        no:'#tw003',
        image:client3,
        name:'Faye Bridger',
        phoneNo:'(+12)85-4521-7568',
        amount:'$546',
        date:'13th March 2023',
        status:'unpaid'
    },
    {
        no:'#tw004',
        image:client4,
        name:'Ronald Curtis',
        phoneNo:'(+12)85-4521-7568',
        amount:'$154',
        date:'6th May 2023',
        status:'Paid'
    },
    {
        no:'#tw005',
        image:client5,
        name:'Melissa Hibner',
        phoneNo:'(+12)85-4521-7568',
        amount:'$458',
        date:'19th June 2023',
        status:'unpaid'
    },
    {
        no:'#tw006',
        image:client6,
        name:'Randall Case',
        phoneNo:'(+12)85-4521-7568',
        amount:'$548',
        date:'20th June 2023',
        status:'Paid'
    },
    {
        no:'#tw007',
        image:client7,
        name:'Jerry Morena',
        phoneNo:'(+12)85-4521-7568',
        amount:'$658',
        date:'31st Aug 2023',
        status:'Paid'
    },
    {
        no:'#tw008',
        image:client8,
        name:'Lester McNally',
        phoneNo:'(+12)85-4521-7568',
        amount:'$457',
        date:'1st Sep 2023',
        status:'Paid'
    },
]
export  const accordion = [
    {
        title:"How does it work ?",
        contant:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
    },
    {
        title:"Do I need a designer to use Techwind ?",
        contant:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
    },
    {
        title:"What do I need to do to start selling ?",
        contant:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
    },
    {
        title:"What happens when I receive an order ?",
        contant:"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
    }
]
export const teamData = [
    {
        image:client1,
        name:'Jack John',
        title:'Designer'
    },
    {
        image:client2,
        name:'Krista John',
        title:'Designer'
    },
    {
        image:client3,
        name:'Roger Jackson',
        title:'Designer'
    },
    {
        image:client4,
        name:'Johnny English',
        title:'Designer'
    },
    {
        image:client5,
        name:'Jack John',
        title:'Designer'
    },
    {
        image:client6,
        name:'Krista John',
        title:'Designer'
    },
    {
        image:client7,
        name:'Roger Jackson',
        title:'Designer'
    },
    {
        image:client8,
        name:'Johnny English',
        title:'Designer'
    },
    {
        image:client9,
        name:'Jack John',
        title:'Designer'
    },
    {
        image:client10,
        name:'Krista John',
        title:'Designer'
    },
    {
        image:client11,
        name:'Roger Jackson',
        title:'Designer'
    },
    {
        image:client12,
        name:'Johnny English',
        title:'Designer'
    },
]
export const paymentData = [
    {
        image:visaImg,
        title:'Visa ending in 4578',
        time:'Expires in 12/2022'
    },
    {
        image:americanimg,
        title:'American Express ending in 4578',
        time:'Expires in 12/2022'
    },
    {
        image:discover,
        title:'Discover ending in 4578',
        time:'Expires in 12/2022'
    },
    {
        image:masterCard,
        title:'Master Card ending in 4578',
        time:'Expires in 12/2022'
    },
]

export const portfolioData = [
    {
        id:0,
        image:portfolio1,
        title1:'Mockup Collection',
        title2:'Abstract'
    },
    {
        id:1,
        image:portfolio2,
        title1:'Mockup Collection',
        title2:'Abstract'
    },
    {
        id:2,
        image:portfolio3,
        title1:'Mockup Collection',
        title2:'Abstract'
    },
    {
        id:3,
        image:portfolio4,
        title1:'Mockup Collection',
        title2:'Abstract'
    },
    {
        id:4,
        image:portfolio5,
        title1:'Mockup Collection',
        title2:'Abstract'
    },
    {
        id:5,
        image:portfolio6,
        title1:'Mockup Collection',
        title2:'Abstract'
    },
]
export const portfolioImage = [
    portfolio1, portfolio2, portfolio3, portfolio4, portfolio5, portfolio6
]
export const experienceData = [
    {
        image:circleLogo,
        title :'Senior Web Developer',
        experience :'3 Years Experience',
        company:'CircleCi',
        location:'@London, UK'
    },
    {
        image:facebookLogo,
        title :'Web Designer',
        experience :'2 Years Experience',
        company:'Facebook',
        location:'@Washington D.C, USA'
    },
    {
        image:spotifyLogo,
        title :'UI Designer',
        experience :'2 Years Experience',
        company:'Spotify',
        location:'@Perth, Australia'
    },
]
export const cryptoData = [
    {
        image:coin1,
        name:'BTC',
        last:'$34587',
        chg:'-$745',
        chgPer:'-2.5%',
        profit:false
    },
    {
        image:coin2,
        name:'LTC',
        last:'$216',
        chg:'+$.264',
        chgPer:'+.264%',
        profit:true
    },
    {
        image:coin3,
        name:'ARC',
        last:'$452',
        chg:'-$1.9',
        chgPer:'-1.9%',
        profit:false
    },
    {
        image:coin4,
        name:'CNY',
        last:'$154',
        chg:'+$1.05',
        chgPer:'+1.05%',
        profit:true
    },
    {
        image:coin5,
        name:'ETH',
        last:'$854',
        chg:'+$1.705',
        chgPer:'+1.705%',
        profit:true
    },
    {
        image:coin6,
        name:'PTC',
        last:'$548',
        chg:'-$3.2',
        chgPer:'-3.2%',
        profit:false
    },
    {
        image:coin7,
        name:'ZCC',
        last:'$965',
        chg:'+$1.465',
        chgPer:'+1.465%',
        profit:true
    },
    {
        image:coin8,
        name:'XPM',
        last:'$34587',
        chg:'-$745',
        chgPer:'-1.08%',
        profit:false,
    },
    {
        image:coin9,
        name:'BLOCK',
        last:'$478',
        chg:'+$2.8',
        chgPer:'+2.8%',
        profit:true
    },
    {
        image:coin10,
        name:'KCS',
        last:'$545',
        chg:'+$1.5',
        chgPer:'+1.5%',
        profit:true
    },
]

export const transections = [
    {
        type:'Buy',
        assets:'BTC',
        date:'13th Sep 2023',
        amount:'$350',
        wallet:'qhut0...hfteh45',
        status:'Progress'
    },
    {
        type:'Sell',
        assets:'BTC',
        date:'29th Nov 2023',
        amount:'1.45 BTC',
        wallet:'qhut0...hfteh45',
        status:'Pending'
    },
    {
        type:'Sell',
        assets:'LTC',
        date:'29th Dec 2023',
        amount:'1.45 LTC',
        wallet:'qhut0...hfteh45',
        status:'Success'
    },
    {
        type:'Buy',
        assets:'ARC',
        date:'13th March 2023',
        amount:'$350',
        wallet:'qhut0...hfteh45',
        status:'Pending'
    },
    {
        type:'Sell',
        assets:'CNY',
        date:'5th May 2023',
        amount:'1.45 CNY',
        wallet:'qhut0...hfteh45',
        status:'Progress'
    },
    {
        type:'Buy',
        assets:'BTC',
        date:'13th Sep 2023',
        amount:'$350',
        wallet:'qhut0...hfteh45',
        status:'Success'
    },
    {
        type:'Buy',
        assets:'ETH',
        date:'19th June 2023',
        amount:'$350',
        wallet:'qhut0...hfteh45',
        status:'Progress'
    },
    {
        type:'Sell',
        assets:'PTC',
        date:'20th June 2023',
        amount:'1.45 PTC',
        wallet:'qhut0...hfteh45',
        status:'Pending'
    },
    {
        type:'Sell',
        assets:'ZCC',
        date:'31st Aug 2023',
        amount:'1.45 ZCC',
        wallet:'qhut0...hfteh45',
        status:'Success'
    },
    {
        type:'Buy',
        assets:'XPM',
        date:'1st Sep 2023',
        amount:'$350',
        wallet:'qhut0...hfteh45',
        status:'Success'
    },
    {
        type:'BLOCK',
        assets:'XPM',
        date:'1st Sep 2023',
        amount:'$350',
        wallet:'qhut0...hfteh45',
        status:'Progress'
    },
]

export const orderCoin = [
    {
        type:'Buy',
        name:'Bitcoin',
        status:'Success',
        spend:'$ 45,456',
        recived:'0.00956BTC'
    },
    {
        type:'Sell',
        name:'Blocknet',
        status:'Pending',
        spend:'0.00956BTC',
        recived:'$ 45,456'
    },
    {
        type:'Sell',
        name:'Litecoin',
        status:'Progress',
        spend:'$ 45,456',
        recived:'0.00956BTC'
    },
    {
        type:'Buy',
        name:'Kucoin',
        status:'Success',
        spend:'$ 45,456',
        recived:'0.00956BTC'
    },
]